import React, { useContext } from 'react';
import { Mask } from 'antd-mobile';
import { Context, setDetailModal } from '../../store';
import './index.less';
var DetailDescription = function () {
    var _a = useContext(Context), state = _a.state, dispatch = _a.dispatch;
    var detailModal = state.detailModal;
    var closeModal = function () {
        dispatch(setDetailModal({
            visible: false
        }));
    };
    return React.createElement("div", { className: 'detail-description-wrapper' },
        React.createElement(Mask, { visible: detailModal.visible, opacity: 0.8 },
            React.createElement("div", { className: 'detail-description-content' },
                React.createElement("img", { onClick: closeModal, className: 'detail-close-icon', src: 'https://cdn.tuanzhzh.com/doubi-image/close-modal-icon.png' }),
                React.createElement("div", { className: 'detail-title' }, "\u8BE6\u7EC6\u8BF4\u660E"),
                React.createElement("ul", { className: 'detail-description-ul' },
                    React.createElement("li", null, "1\u3001\u4E0D\u540C\u76D2\u5B50\u5185\u4EA7\u51FA\u7684\u793C\u7269\u4E0D\u540C\uFF0C\u793C\u7269\u4E0E\u6982\u7387\u8BE6\u89C1\u9644\u8868"),
                    React.createElement("li", null, "2\u3001\u4E0D\u540C\u7C7B\u578B\u7684\u76D2\u5B50\u4EC5\u53EF\u7528\u5BF9\u5E94\u989C\u8272\u7684\u94A5\u5319\u5F00\u542F"),
                    React.createElement("li", null, "3\u3001\u6BCF\u4E00\u79CD\u76D2\u5B50\u572880\u6B21\u5F00\u542F\u5185\uFF0C\u5FC5\u51FA\u81F3\u5C111\u4EF6\u91D1\u8272\u793C\u7269\uFF1B"),
                    React.createElement("li", null, "4\u3001\u60A8\u83B7\u5F97\u7684\u793C\u7269\uFF0C\u5C06\u5728\u60A8\u786E\u8BA4\u63D0\u53D6\u540E\uFF0C\u4EE5\u6296\u5E01\u7684\u5F62\u5F0F\u8FDB\u5165\u60A8\u6307\u5B9A\u7684\u8D26\u6237\u4E2D\uFF1B"),
                    React.createElement("li", { className: 'probability-li' }, "\u91D1\u95EA\u76D2\u5B50-\u4F7F\u7528\u91D1\u94A5\u5319:"),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u7EA2\u8272\u793C\u7269"),
                        React.createElement("span", null, "1%\uFF08\u989D\u5916\u6982\u7387\uFF09"),
                        React.createElement("span", null, "\u5609\u5E74\u534E\u3001\u7CD6\u679C\u98DE\u8239\u3001\u706B\u7BAD")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u91D1\u8272\u793C\u7269"),
                        React.createElement("span", null, "5%"),
                        React.createElement("span", null, "\u4FDD\u65F6\u6377")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u7D2B\u8272\u793C\u7269"),
                        React.createElement("span", null, "15%"),
                        React.createElement("span", null, "\u661F\u661F\u70B9\u706F\u3001\u7231\u7684\u5B88\u62A4")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u84DD\u8272\u793C\u7269"),
                        React.createElement("span", null, "50%"),
                        React.createElement("span", null, "\u7231\u4F60\u54DF*4\u3001\u9001\u4F60\u82B1\u82B1*4\u3001\u9C9C\u82B1*18")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u767D\u8272\u793C\u7269"),
                        React.createElement("span", null, "30%"),
                        React.createElement("span", null, "\u7231\u4F60\u54DF\u3001\u9C9C\u82B1*6\u3001\u68D2\u68D2\u7CD6*8\u3001\u52A0\u6CB9\u9E2D*5")),
                    React.createElement("li", { className: 'probability-li' }, "\u94F6\u5149\u76D2\u5B50-\u4F7F\u7528\u94F6\u94A5\u5319:"),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u7EA2\u8272\u793C\u7269"),
                        React.createElement("span", null, "1%\uFF08\u989D\u5916\u6982\u7387\uFF09"),
                        React.createElement("span", null, "\u7CD6\u679C\u98DE\u8239\u3001\u706B\u7BAD")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u91D1\u8272\u793C\u7269"),
                        React.createElement("span", null, "5%"),
                        React.createElement("span", null, "\u7231\u7684\u5B88\u62A4")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u7D2B\u8272\u793C\u7269"),
                        React.createElement("span", null, "15%"),
                        React.createElement("span", null, "\u4EB2\u543B\u3001\u7231\u7684\u7EB8\u9E64")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u84DD\u8272\u793C\u7269"),
                        React.createElement("span", null, "50%"),
                        React.createElement("span", null, "\u9C9C\u82B1*6\u3001\u68D2\u68D2\u7CD6*8\u3001\u52A0\u6CB9\u9E2D*5")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u767D\u8272\u793C\u7269"),
                        React.createElement("span", null, "30%"),
                        React.createElement("span", null, "\u5927\u5564\u9152*9\u3001\u68D2\u68D2\u7CD6*2\u3001\u9C9C\u82B1*2\u3001\u73AB\u7470*25")),
                    React.createElement("li", { className: 'probability-li' }, "\u94DC\u8D28\u76D2\u5B50-\u4F7F\u7528\u91D1\u94A5\u5319:"),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u7EA2\u8272\u793C\u7269"),
                        React.createElement("span", null, "1%\uFF08\u989D\u5916\u6982\u7387\uFF09"),
                        React.createElement("span", null, "\u706B\u7BAD")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u91D1\u8272\u793C\u7269"),
                        React.createElement("span", null, "5%"),
                        React.createElement("span", null, "\u9C9C\u82B1*4")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u7D2B\u8272\u793C\u7269"),
                        React.createElement("span", null, "15%"),
                        React.createElement("span", null, "\u9C9C\u82B1*2\u3001\u5927\u5564\u9152*9")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u84DD\u8272\u793C\u7269"),
                        React.createElement("span", null, "50%"),
                        React.createElement("span", null, "\u68D2\u68D2\u7CD6\u3001\u9C9C\u82B1\u3001\u52A0\u6CB9\u9E2D")),
                    React.createElement("li", { className: 'detail-description-li' },
                        React.createElement("span", null, "\u767D\u8272\u793C\u7269"),
                        React.createElement("span", null, "30%"),
                        React.createElement("span", null, "\u5C0F\u5FC3\u5FC3\u3001\u4F60\u6700\u597D\u770B\u3001\u5927\u5564\u9152*2\u3001\u73AB\u7470*5"))))));
};
export default DetailDescription;
