var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useState } from 'react';
import { NavBar, Form, Input, Button, Toast as ShowComToast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import Toast from '../Component/Toast';
import Apis from 'src/apis';
import './index.less';
var count = 60;
var ForgetPassword = function () {
    var _a = useState('获取验证码'), verificaationText = _a[0], setVerificaationText = _a[1];
    var _b = useState(false), showToast = _b[0], setShowToast = _b[1];
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var back = useCallback(function () {
        navigate({
            pathname: '/login',
            search: window.location.search
        });
    }, []);
    var pkgNameRule = {
        pattern: /^1[3-9]\d{9}$/,
        message: '手机号不合法'
    };
    var passwordRule = {
        pattern: /^[a-zA-Z0-9]{8,16}$/,
        message: '8~16位数字、英文混合'
    };
    var registerHandle = function () { return __awaiter(void 0, void 0, void 0, function () {
        var obj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.validateFields()];
                case 1:
                    obj = _a.sent();
                    Apis.getBackPwd(obj).then(function (res) {
                        // 找回成功
                        if (res.data.resetResult) {
                            setShowToast(true);
                            setTimeout(function () {
                                setShowToast(false);
                            }, 2000);
                            navigate({
                                pathname: '/login',
                                search: window.location.search
                            });
                        }
                        else {
                            ShowComToast.show({
                                icon: 'fail',
                                content: res.data.resetMsg
                            });
                        }
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var getVerificationCode = useCallback(function () {
        if (count < 60)
            return;
        form.validateFields(['phoneNum']).then(function (res) {
            console.log(res.data);
            var timerId = null;
            verificationCode();
            setVerificaationText("".concat(count, " s"));
            timerId = setTimeout(function run() {
                if (count < 1) {
                    clearTimeout(timerId);
                    setVerificaationText('获取验证码');
                    count = 60;
                    return;
                }
                count -= 1;
                timerId = setTimeout(run, 1000);
                setVerificaationText("".concat(count, " s"));
            }, 1000);
        }, function (err) {
            console.log(err);
        });
    }, []);
    var verificationCode = function () {
        Apis.getVerifyCode({
            phoneNum: form.getFieldsValue().phoneNum
        }).then(function (res) {
            console.log(res.data);
            if (!res.data.sendResult) {
                ShowComToast.show({
                    icon: 'fail',
                    content: res.data.sendMsg
                });
            }
        }, function (err) {
            ShowComToast.show({
                icon: 'fail',
                content: err.sendMsg
            });
        });
    };
    return React.createElement("div", { className: 'forget-password-wrapper' },
        React.createElement(NavBar, { onBack: back, back: '\u8FD4\u56DE' }, "\u627E\u56DE\u5BC6\u7801"),
        React.createElement(Form, { layout: 'horizontal', className: 'forget-form', form: form, requiredMarkStyle: 'none' },
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u624B\u673A\u53F7"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { name: 'phoneNum', validateTrigger: ['onBlur'], rules: [
                            { required: true, message: '请输入11位手机号' },
                            pkgNameRule
                        ] },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u516511\u4F4D\u624B\u673A\u53F7', clearable: true, className: 'input-style' })))),
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u9A8C\u8BC1\u7801"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { noStyle: true, name: 'verifyCode', rules: [{ required: true, message: '请输入验证码' }] },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u9A8C\u8BC1\u7801', clearable: true, className: 'input-style' })),
                    React.createElement(Button, { block: true, shape: 'rounded', className: 'verification-btn', onClick: getVerificationCode }, verificaationText))),
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u65B0\u5BC6\u7801"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { name: 'newPasswd', validateTrigger: ['onBlur'], rules: [
                            { required: true, message: '8~16位数字、英文混合' },
                            passwordRule
                        ] },
                        React.createElement(Input, { type: 'password', placeholder: '8~16\u4F4D\u6570\u5B57\u3001\u82F1\u6587\u6DF7\u5408', clearable: true, className: 'input-style' }))))),
        React.createElement("div", { className: 'login-btn login-register-btn', onClick: registerHandle },
            React.createElement("span", null, "\u786E\u5B9A")),
        React.createElement(Toast, { content: '\u5BC6\u7801\u4FEE\u6539\u6210', visible: showToast }));
};
export default ForgetPassword;
