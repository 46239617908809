var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
import React, { useEffect, useState } from 'react';
import Api from '../../apis';
import { Divider, CapsuleTabs, Button, Modal, Toast, Space } from 'antd-mobile';
import RollingList from '@pages/Component/RollingList';
import { useNavigate } from 'react-router-dom';
// import TopRolling from '@pages/Component/TopRolling'
// import TopRolling2 from '@pages/Component/TopRolling/index'
import GiftBroadcast from '@pages/Component/GiftBroadcast';
import useEnhancedReducerv from '../Component/UseEnhancedReducer';
import reducer, { Context, setViewModal, initialState, setDetailModal, lotteryDraw, getKeys, setExchangeCodeModal, setShareModal
// setNoticeModal
 } from './store';
import RechargeKey from './component/RechargeKey';
import DetailDescription from './component/DetailDescription';
// import NoticeModal from './component/NoticeModal'
import ExchangeCode from './component/ExchangeCode';
import Lottery from './component/LotteryModal';
import { getUrlParams } from 'src/utils';
// import CustomServiceModal from '../Component/CustomServiceModal'
import Share from './component/Share';
// import moment from 'moment'
// import { Context as globalContext, setCustomServiceModal } from '../../store'
import './index.less';
var boxMap = {
    3: 'https://cdn.tuanzhzh.com/doubi-image/tong-box2.png',
    4: 'https://cdn.tuanzhzh.com/doubi-image/yin-box2.png',
    5: 'https://cdn.tuanzhzh.com/doubi-image/jin-box.png'
};
var keyIcon = {
    3: 'https://cdn.tuanzhzh.com/doubi-image/tong-yaoshi.png',
    4: 'https://cdn.tuanzhzh.com/doubi-image/yin-yaoshi.png',
    5: 'https://cdn.tuanzhzh.com/doubi-image/jin-yaoshi.png'
};
var Home = function () {
    var _a = useState(3), currentBox = _a[0], setCurrentBox = _a[1];
    var _b = useState({
        nickName: '请登录',
        avatarUrl: 'https://cdn.tuanzhzh.com/doubi-image/no-login-icon.png'
    }), loginInfo = _b[0], setLoginInfo = _b[1];
    // const { dispatch: globalDispatch } = useContext(globalContext)
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var _c = useEnhancedReducerv(reducer, initialState), state = _c[0], dispatch = _c[1];
    var navigate = useNavigate();
    var keyInfo = state.keyInfo, showCurrentBroadCast = state.showCurrentBroadCast;
    var skipLoginPage = function () {
        if (!userInfo.userId) {
            navigate({
                pathname: '/login',
                search: window.location.search
            });
        }
    };
    // const contactCustomService = () => {
    //   globalDispatch(setCustomServiceModal({
    //     visible: true
    //   }))
    // }
    var extractHandle = function () {
        navigate({
            pathname: '/extract',
            search: window.location.search
        });
    };
    var addRechargeKey = function (type, num) {
        if (!userInfo.userId) {
            return Toast.show({
                content: '请先登录',
                duration: 2000
            });
        }
        dispatch(setViewModal({
            visible: true,
            num: num,
            type: type
        }));
    };
    var capsuleTabChange = function (key) {
        setCurrentBox(key);
    };
    var showDetailDescription = function () {
        dispatch(setDetailModal({
            visible: true
        }));
    };
    var showLotteryModal = function (num) {
        // lotteryDraw(num)
        var currentKeyInfo = keyInfo.find(function (item) { return item.keyType === currentBox * 1; });
        if (currentKeyInfo.keyCount < num) {
            Toast.show({
                content: '请点击上方+按钮购买钥匙',
                duration: 2000
            });
            return;
        }
        if (currentKeyInfo.keyCount === 0) {
            dispatch(setViewModal({
                visible: true,
                num: currentKeyInfo.keyCount,
                type: currentBox * 1
            }));
            return;
        }
        dispatch(lotteryDraw({
            userId: userInfo.userId,
            keyInfo: {
                keyType: currentBox * 1,
                keyCount: num
            }
        }));
    };
    var logout = function () {
        try {
            window.localStorage.removeItem('user');
            Toast.show({ content: '退出成功', position: 'center' });
            setLoginInfo({
                nickName: '请登录',
                avatarUrl: 'https://cdn.tuanzhzh.com/doubi-image/no-login-icon.png'
            });
            // forceUpdate()
        }
        catch (error) {
            Toast.show({ content: '退出失败', position: 'center' });
        }
    };
    var exchangeHandle = function () {
        dispatch(setExchangeCodeModal({
            visible: true
        }));
    };
    var getUserInfo = function () {
        Toast.show({
            icon: 'loading',
            content: '加载中…'
        });
        Api.getUserInfo({
            userId: userInfo.userId
        }).then(function (res) {
            Toast.clear();
            dispatch(setShareModal({
                visible: true,
                data: res.data || {}
            }));
        });
    };
    var shareHandle = function () {
        getUserInfo();
    };
    // const getNotice = () => {
    //   const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
    //   const saveTime = window.localStorage.getItem('saveTime')
    //   if (!saveTime) {
    //     window.localStorage.setItem('saveTime', currentTime)
    //     getNoticeData()
    //   } else {
    //     const getSaveTime = window.localStorage.getItem('saveTime')
    //     const diff = moment(currentTime).valueOf() - moment(getSaveTime).valueOf()
    //     if (diff > 24 * 60 * 60 * 1000) {
    //       window.localStorage.setItem('saveTime', currentTime)
    //       getNoticeData()
    //     }
    //   }
    // }
    // 获取首页公告
    // const getNoticeData = () => {
    //   Api.getNotice().then(res => {
    //     dispatch(setNoticeModal({
    //       visible: true,
    //       data: res.data || {}
    //     }))
    //   })
    // }
    useEffect(function () {
        if (Object.keys(userInfo).length) {
            dispatch(getKeys({
                userId: userInfo.userId
            }));
            setLoginInfo(__assign({}, userInfo));
        }
        var tempObj = getUrlParams();
        if (Object.keys(tempObj).length && tempObj.origin === 'finishPay') {
            Modal.show({
                content: React.createElement("div", { className: 'modal-echarge-wrapper' },
                    React.createElement("span", { className: 'modal-echarge-title' },
                        "\u60A8\u5DF2\u8D2D\u6210\u529F\u5145\u503C",
                        React.createElement("span", { className: 'modal-echarge-success' }, tempObj.keyCount),
                        "\u628A"),
                    React.createElement("img", { className: 'modal-echarge-success-key', src: keyIcon[tempObj.keyType] })),
                actions: [{
                        key: 'online',
                        text: '确认',
                        className: 'modal-echarge-success-btn',
                        onClick: function () {
                            window.location.replace(window.location.origin + window.location.pathname);
                            Modal.clear();
                        }
                    }]
            });
        }
    }, []);
    // useEffect(() => {
    //   if (userInfo.userId) {
    //     getNotice()
    //   }
    // })
    return React.createElement(React.Fragment, null,
        React.createElement(Context.Provider, { value: { state: state, dispatch: dispatch } },
            React.createElement("div", { className: 'home-page' },
                React.createElement(GiftBroadcast, { showCurrentUser: showCurrentBroadCast }),
                React.createElement("div", { className: 'home-user-wrapper' },
                    React.createElement("div", { className: 'home-user-content' },
                        React.createElement("span", { className: 'user-portrait' },
                            React.createElement("img", { className: 'portrait-icon', src: loginInfo.avatarUrl || 'https://cdn.tuanzhzh.com/doubi-image/no-login-icon.png' })),
                        React.createElement("span", { className: 'user-name', onClick: skipLoginPage }, loginInfo.nickName || '游客'),
                        React.createElement(Button, { color: 'danger', className: userInfo.userId ? 'user-logout-btn' : 'hide user-logout-btn', size: 'mini', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, Modal.confirm({
                                                content: '您确定要退出么？',
                                                bodyClassName: 'user-logout-modal'
                                            })];
                                        case 1:
                                            result = _a.sent();
                                            if (result) {
                                                logout();
                                                // Toast.show({ content: '点击了确认', position: 'center' })
                                            }
                                            else {
                                                // Toast.show({ content: '点击了取消', position: 'center' })
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }, "\u9000\u51FA")),
                    React.createElement(Space, { className: 'home-operation-btn' },
                        React.createElement("label", { onClick: shareHandle }, "\u5206\u4EAB"),
                        React.createElement("label", { onClick: exchangeHandle }, "\u5151\u6362\u94A5\u5319"))),
                React.createElement("div", { className: 'home-keys-wrapper' }, keyInfo.map(function (item, i) {
                    return React.createElement("div", { className: 'key-item', key: i },
                        React.createElement("img", { className: 'key-type', src: keyIcon[item.keyType] }),
                        React.createElement("span", { className: 'key-num' }, item.keyCount),
                        React.createElement("span", { className: 'key-add-btn', onClick: addRechargeKey.bind(_this, item.keyType, item.keyCount) },
                            React.createElement("img", { className: 'add-plus-icon', src: 'https://cdn.tuanzhzh.com/doubi-image/plus.png' })));
                })),
                React.createElement("img", { style: { display: 'none' }, src: 'https://cdn.tuanzhzh.com/doubi-image/open-dh.png' }),
                React.createElement(Divider, { className: 'home-keys-divider' }),
                React.createElement(CapsuleTabs, { className: 'blind-box-btn-wrapper', onChange: capsuleTabChange },
                    React.createElement(CapsuleTabs.Tab, { title: '\u94DC\u8D28\u76D2\u5B50', key: 3 }),
                    React.createElement(CapsuleTabs.Tab, { title: '\u94F6\u5149\u76D2\u5B50', key: 4 }),
                    React.createElement(CapsuleTabs.Tab, { title: '\u91D1\u95EA\u76D2\u5B50', key: 5 })),
                React.createElement("div", { className: 'home-box-xiang-wrapper' },
                    React.createElement("img", { className: 'box-xiang-icon', src: boxMap[currentBox] }),
                    React.createElement("span", { className: 'box-xiang-instructions', onClick: showDetailDescription }, "\u8BE6\u7EC6\u8BF4\u660E")),
                React.createElement("div", { className: 'home-box-xiang-btn-wrapper' },
                    React.createElement(Button, { shape: 'rounded', className: 'one-key-btn', onClick: showLotteryModal.bind(_this, 1) },
                        React.createElement("span", null, "\u62BD1\u6B21")),
                    React.createElement(Button, { shape: 'rounded', className: 'ten-key-btn', onClick: showLotteryModal.bind(_this, 10) },
                        React.createElement("span", null, "10\u8FDE\u62BD"))),
                React.createElement("div", { className: 'home-doubi-extract', onClick: extractHandle },
                    React.createElement("img", { className: 'doubi-tiqu', src: 'https://cdn.tuanzhzh.com/doubi-image/doubi-tiqu-icon.png' }),
                    React.createElement("span", { className: 'doubi-tiqu-text' }, "\u6296\u5E01\u63D0\u53D6")),
                React.createElement(RollingList, null)),
            React.createElement(RechargeKey, null),
            React.createElement(DetailDescription, null),
            React.createElement(Lottery, null),
            React.createElement(ExchangeCode, null),
            React.createElement(Share, null)));
};
export default Home;
