var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { Toast } from 'antd-mobile';
import Apis from 'src/apis';
var flag = true;
export var Context = React.createContext(null);
export var initialState = {
    viewModal: {
        visible: false,
        type: 3,
        num: 0
    },
    detailModal: {
        visible: false
    },
    noticeModal: {
        visible: false,
        data: {}
    },
    lotteryModal: {
        visible: false,
        currentBoxType: 3,
        lotteryDataSource: {}
    },
    keyInfo: [{
            keyType: 3,
            keyCount: 0
        }, {
            keyType: 4,
            keyCount: 0
        }, {
            keyType: 5,
            keyCount: 0
        }],
    commonScreenData: [],
    exchangeCodeModal: {
        visible: false
    },
    shareModal: {
        visible: false,
        data: {}
    },
    showCurrentBroadCast: {
        img: '',
        value: ''
    }
};
export var reduxSlice = createSlice({
    name: 'reduxSlice',
    initialState: initialState,
    reducers: {
        // support Immer
        setViewModal: function (state, _a) {
            var payload = _a.payload;
            state.viewModal = payload;
        },
        setDetailModal: function (state, _a) {
            var payload = _a.payload;
            state.detailModal = payload;
        },
        setNoticeModal: function (state, _a) {
            var payload = _a.payload;
            state.noticeModal = payload;
        },
        setLotteryModal: function (state, _a) {
            var payload = _a.payload;
            state.lotteryModal = payload;
        },
        setKeyInfo: function (state, _a) {
            var payload = _a.payload;
            state.keyInfo = payload;
        },
        setCommonScreenData: function (state, _a) {
            var payload = _a.payload;
            state.commonScreenData = payload;
        },
        setExchangeCodeModal: function (state, _a) {
            var payload = _a.payload;
            state.exchangeCodeModal = payload;
        },
        setShareModal: function (state, _a) {
            var payload = _a.payload;
            state.shareModal = payload;
        },
        setShowCurrentBroadCast: function (state, _a) {
            var payload = _a.payload;
            state.showCurrentBroadCast = payload;
        }
    }
});
// Action creators are generated for each case reducer function
export var setViewModal = (_a = reduxSlice.actions, _a.setViewModal), setDetailModal = _a.setDetailModal, setLotteryModal = _a.setLotteryModal, setKeyInfo = _a.setKeyInfo, setCommonScreenData = _a.setCommonScreenData, setExchangeCodeModal = _a.setExchangeCodeModal, setShareModal = _a.setShareModal, setShowCurrentBroadCast = _a.setShowCurrentBroadCast, setNoticeModal = _a.setNoticeModal;
// 获取钥匙数量
export var getKeys = function (query) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var res;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, Apis.getKeys(query).catch(function (err) {
                    Toast.show({
                        content: err.msg
                    });
                })];
            case 1:
                res = _b.sent();
                dispatch(setKeyInfo((_a = res.data.keyCountInfoList) !== null && _a !== void 0 ? _a : []));
                return [2 /*return*/];
        }
    });
}); }; };
// 获取公屏信息
export var getCommonScreen = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var res, tempArr;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, Apis.getCommonScreen().catch(function (err) {
                    Toast.show({
                        content: err.msg
                    });
                })];
            case 1:
                res = _b.sent();
                tempArr = [];
                (_a = res.data) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
                    tempArr.push({
                        userName: item.user.nickName,
                        giftName: item.gift.giftName,
                        dyMoneyAmount: item.gift.dyMoneyAmount
                    });
                });
                tempArr = tempArr.concat(tempArr);
                dispatch(setCommonScreenData(tempArr));
                return [2 /*return*/];
        }
    });
}); }; };
// 开盒子
var timerId;
var timerId2;
export var lotteryDraw = function (query) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            if (!flag)
                return [2 /*return*/];
            flag = false;
            dispatch(setLotteryModal({
                visible: true,
                currentBoxType: (_a = query.keyInfo) === null || _a === void 0 ? void 0 : _a.keyType,
                lotteryDataSource: {}
            }));
            if (timerId) {
                clearTimeout(timerId);
            }
            Toast.show({
                icon: 'loading',
                content: '加载中…',
                duration: 1500
            });
            timerId = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var res;
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, Apis.lotteryDraw(query).catch(function (err) {
                                if (err.response.status === 500) {
                                    flag = true;
                                }
                            })];
                        case 1:
                            res = _c.sent();
                            if (res.data.drawSuccess) {
                                Toast.clear();
                                dispatch(setLotteryModal({
                                    visible: true,
                                    currentBoxType: (_a = query.keyInfo) === null || _a === void 0 ? void 0 : _a.keyType,
                                    lotteryDataSource: (_b = res.data.lottery) !== null && _b !== void 0 ? _b : {}
                                }));
                                dispatch(getKeys({
                                    userId: query.userId
                                }));
                                dispatch(getCommonScreen());
                            }
                            else {
                                Toast.show({
                                    content: res.data.drawFailedMsg,
                                    duration: 2000
                                });
                            }
                            if (timerId2) {
                                clearTimeout(timerId2);
                            }
                            timerId2 = setTimeout(function () {
                                flag = true;
                            }, 1000);
                            return [2 /*return*/];
                    }
                });
            }); }, query.keyInfo.keyCount === 1 ? 2000 : 200);
            return [2 /*return*/];
        });
    }); };
};
// Reducer
export default reduxSlice.reducer;
