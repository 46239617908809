import React, { useContext } from 'react';
import { Modal, Space } from 'antd-mobile';
import { Context, setCustomServiceModal } from '../../../store';
import './index.less';
var CustomServiceModal = function () {
    var _a = useContext(Context), state = _a.state, dispatch = _a.dispatch;
    var close = function () {
        dispatch(setCustomServiceModal({
            visible: false
        }));
    };
    return React.createElement(Modal, { title: '', visible: state.customServiceModal.visible, showCloseButton: true, onClose: close, bodyClassName: 'custom-service-modal-wrapper', content: React.createElement("div", { className: 'custom-service-content-wrapper' },
            React.createElement(Space, { direction: 'vertical', block: true },
                React.createElement("span", null, "\u8BF7\u626B\u63CF\u5BA2\u670D\u5FAE\u4FE1"),
                React.createElement("img", { src: 'https://cdn.tuanzhzh.com/doubi-image/Wechat-k.jpeg' }))) });
};
export default CustomServiceModal;
