import React, { useContext } from 'react';
import { Modal, Space } from 'antd-mobile';
import { Context, setRenewModalData } from '../../../../store';
import './index.less';
var levelMap = {
    1: '一级推广',
    2: '二级推广'
};
var RenewModal = function () {
    var _a = useContext(Context), state = _a.state, dispatch = _a.dispatch;
    var close = function () {
        dispatch(setRenewModalData({
            visible: false
        }));
    };
    return React.createElement(Modal, { title: '\u7EED\u671F', visible: state.renewModalData.visible, showCloseButton: true, onClose: close, bodyClassName: 'renew-modal-wrapper', content: React.createElement("div", { className: 'renew-content-wrapper' },
            React.createElement(Space, { direction: 'vertical', block: true },
                React.createElement("span", null,
                    React.createElement("label", null, "\u60A8\u7684\u7EA7\u522B\u4E3A\uFF1A"),
                    React.createElement("label", { className: 'item-value' }, levelMap[state.renewModalData.level])),
                React.createElement("span", null, "\u8BF7\u626B\u63CF\u5BA2\u670D\u5FAE\u4FE1\u8FDB\u884C\u7EED\u671F"),
                React.createElement("img", { src: 'https://cdn.tuanzhzh.com/doubi-image/Wechat-k.jpeg' }))) });
};
export default RenewModal;
