import React, { useEffect, useContext } from 'react';
import { ErrorBlock } from 'antd-mobile';
import { Context, getCommonScreen } from 'src/pages/Home/store';
import './index.less';
var RollingList = function () {
    var _a = useContext(Context), state = _a.state, dispatch = _a.dispatch;
    var commonScreenData = state.commonScreenData;
    useEffect(function () {
        dispatch(getCommonScreen());
    }, []);
    return React.createElement("div", { className: 'rolling-list-wrapper' },
        React.createElement("div", { className: 'list-content' }, commonScreenData.map(function (item, i) {
            return React.createElement("div", { className: 'list-item', id: "listItem".concat(i), key: i },
                React.createElement("span", null,
                    React.createElement("label", { className: 'list-item-name' }, item.userName.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')),
                    React.createElement("label", { className: 'list-item-prize-name' },
                        "\u5F00\u51FA\u4E86", "\u201D".concat(item.giftName, "\u201C"))),
                React.createElement("span", null,
                    React.createElement("label", { className: 'list-item-value' }, "\u4EF7\u503C\u6296\u5E01"),
                    React.createElement("label", { className: 'list-item-num' }, item.dyMoneyAmount)));
        })),
        !commonScreenData.length && React.createElement(ErrorBlock, { className: 'err-block', title: null, image: 'https://cdn.tuanzhzh.com/doubi-image/home-empty.png', description: React.createElement("span", null, "\u6682\u65E0\u5F00\u5956\u4FE1\u606F") }));
};
export default RollingList;
