var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { Mask } from 'antd-mobile';
import { Context, setShareModal } from '../../store';
import './index.less';
var Share = function () {
    var _a;
    var _b = useContext(Context), state = _b.state, dispatch = _b.dispatch;
    var shareModal = state.shareModal;
    var closeModal = function () {
        dispatch(setShareModal(__assign(__assign({}, shareModal), { visible: false })));
    };
    return React.createElement("div", { className: 'share-wrapper' },
        React.createElement(Mask, { visible: shareModal.visible, opacity: 0.8 },
            React.createElement("div", { className: 'share-content-wrapper' },
                React.createElement("img", { onClick: closeModal, className: 'share-key-close-icon', src: 'https://cdn.tuanzhzh.com/doubi-image/close-modal-icon.png' }),
                React.createElement("div", { className: 'share-content' },
                    React.createElement("div", { className: 'share-title' }, "\u9080\u8BF7\u670B\u53CB\u62FF\u94A5\u5319"),
                    React.createElement("div", { className: 'share-gift' },
                        React.createElement("img", { src: 'https://cdn.tuanzhzh.com/doubi-image/share1.png' }),
                        React.createElement("img", { src: 'https://cdn.tuanzhzh.com/doubi-image/share2.png' }),
                        React.createElement("img", { src: 'https://cdn.tuanzhzh.com/doubi-image/share3.png' })),
                    React.createElement("img", { className: 'share-code', src: (_a = shareModal.data) === null || _a === void 0 ? void 0 : _a.shareQrCodeUrl }),
                    React.createElement("div", { className: 'share-text-style' },
                        React.createElement("span", null, "\u6BCF\u9080\u8BF71\u540D\u7528\u6237\u6CE8\u518C\uFF0C\u53CC\u65B9\u5404\u83B7\u5F973\u628A\u94DC\u94A5\u5319"),
                        React.createElement("span", null, "\u6BCF\u540D\u7528\u6237\u6BCF\u65E5\u6700\u591A\u53EF\u901A\u8FC7\u9080\u8BF7\u83B7\u5F979\u628A\u94DC\u94A5\u5319"))))));
};
export default Share;
