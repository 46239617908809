var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from 'react';
import { NavBar, Space, Popup, Form, Input, DatePicker, Toast, InfiniteScroll } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Apis from 'src/apis';
import './index.less';
var BigProxy = function () {
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState(false), startFlag = _b[0], setStartFlag = _b[1];
    var _c = useState(false), endFlag = _c[0], setEndFlag = _c[1];
    var _d = useState(false), datePickerVisible = _d[0], setDatePickerVisible = _d[1];
    var _e = useState([]), dataList = _e[0], setDataList = _e[1];
    var _f = useState(true), hasMore = _f[0], setHasMore = _f[1];
    var _g = useState({}), saleData = _g[0], setSaleData = _g[1];
    // const [param, setParam] = useState({
    //   startTime: moment().subtract(7, 'day').startOf('day').valueOf(),
    //   endTime: moment().subtract(1, 'day').startOf('day').valueOf(),
    //   page: 1,
    //   pageSize: 30,
    //   proxyUserId: 'dd5821c9c0744a5383f43ee62a458acc' // userInfo.userId
    // })
    var userInfo = JSON.parse(window.localStorage.getItem('system-user') || '{}');
    var paramRef = useRef({
        startTime: moment().subtract(7, 'day').startOf('day').valueOf(),
        endTime: moment().subtract(1, 'day').startOf('day').valueOf(),
        page: 1,
        pageSize: 30,
        proxyUserId: userInfo.userId
    });
    var form = Form.useForm()[0];
    var navigate = useNavigate();
    var back = function () {
        navigate({
            pathname: '/extension-home',
            search: window.location.search
        });
    };
    var showFilter = function () {
        setVisible(true);
    };
    var searchHandle = function () { return __awaiter(void 0, void 0, void 0, function () {
        var value;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.validateFields()];
                case 1:
                    value = _a.sent();
                    paramRef.current = {
                        startTime: moment(value.startTime).valueOf(),
                        endTime: moment(value.endTime).valueOf(),
                        page: 1,
                        pageSize: 30,
                        proxyUserId: userInfo.userId
                    };
                    getList();
                    setVisible(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var dataPickerClose = function () {
        setDatePickerVisible(false);
        if (startFlag)
            setStartFlag(false);
        if (endFlag)
            setEndFlag(false);
    };
    var dataPickerConfirm = function (val) {
        var time = moment(val).format('YYYY/MM/DD');
        if (startFlag) {
            form.setFieldValue('startTime', time);
        }
        if (endFlag) {
            form.setFieldValue('endTime', time);
        }
    };
    var getList = function () {
        return Apis.getSubList(__assign({}, paramRef.current)).then(function (res) {
            return res;
        }).catch(function () {
            setHasMore(false);
            Toast.show({
                icon: 'fail',
                content: '明细出错'
            });
        });
    };
    function loadMore() {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getList()];
                    case 1:
                        res = _a.sent();
                        if (res.data.detailList.length) {
                            paramRef.current = __assign(__assign({}, paramRef.current), { page: paramRef.current.page + 1 });
                        }
                        setDataList(function (val) { return __spreadArray(__spreadArray([], val, true), res.data.detailList, true); });
                        setHasMore(res.data.detailList.length > 0);
                        return [2 /*return*/];
                }
            });
        });
    }
    var getSaleData = function () {
        Apis.getSaleData({
            userId: userInfo.userId // 'dd5821c9c0744a5383f43ee62a458acc '
        }).then(function (res) {
            var tempMap = ['ytdWaterAmount', 'ytdProfit', 'curMonthWaterAmount', 'curMonthProfit', 'historyAllWaterAmount', 'historyAllProfit', 'toSettledProfit'];
            Object.keys(res.data || {}).forEach(function (item) {
                if (tempMap.includes(item)) {
                    res.data[item] = (res.data[item] / 100).toFixed(2);
                }
            });
            setSaleData(res.data || {});
        }).catch(function () {
            Toast.show({
                icon: 'fail',
                content: '流水出错'
            });
        });
    };
    useEffect(function () {
        getSaleData();
    }, []);
    return React.createElement("div", { className: 'sociaty-small-proxy-wrapper' },
        React.createElement(NavBar, { back: '\u8FD4\u56DE', onBack: back }, "\u63A8\u5E7F\u660E\u7EC6"),
        React.createElement(Space, { className: 'sociaty-small-month' },
            React.createElement(Space, null,
                React.createElement("span", null, "\u6628\u65E5\u6D41\u6C34"),
                React.createElement("span", null, saleData.ytdWaterAmount || 0)),
            React.createElement(Space, null,
                React.createElement("span", null, "\u6628\u65E5\u6536\u76CA"),
                React.createElement("span", null, saleData.ytdProfit || 0))),
        React.createElement(Space, { className: 'sociaty-small-month' },
            React.createElement(Space, null,
                React.createElement("span", null, "\u672C\u6708\u6D41\u6C34"),
                React.createElement("span", null, saleData.curMonthWaterAmount || 0)),
            React.createElement(Space, null,
                React.createElement("span", null, "\u672C\u6708\u6536\u76CA"),
                React.createElement("span", null, saleData.curMonthProfit || 0))),
        React.createElement(Space, { className: 'sociaty-small-month' },
            React.createElement(Space, null,
                React.createElement("span", null, "\u5386\u53F2\u6D41\u6C34"),
                React.createElement("span", null, saleData.historyAllWaterAmount || 0)),
            React.createElement(Space, null,
                React.createElement("span", null, "\u5386\u53F2\u6536\u76CA"),
                React.createElement("span", null, saleData.historyAllProfit || 0))),
        React.createElement(Space, { className: 'sociaty-small-month' },
            React.createElement(Space, null,
                React.createElement("span", null, "\u5F85\u7ED3\u7B97\u6536\u76CA"),
                React.createElement("span", null, saleData.toSettledProfit || 0)),
            React.createElement(Space, null,
                React.createElement("span", null, "\u7ED3\u7B97\u5468\u671F"),
                React.createElement("span", null,
                    "\u6BCF\u6708",
                    saleData.settleDayOfMonth || 0,
                    "\u53F7"))),
        React.createElement("div", { className: 'sociaty-filter' },
            React.createElement("span", { className: 'sociaty-filter-btn', onClick: showFilter }, "\u7B5B\u9009")),
        React.createElement("div", { className: 'sociaty-fitler-condition-show' },
            React.createElement("span", null, "\u5F53\u524D\u6761\u4EF6\uFF1A"),
            React.createElement("span", null, "2023/01/26~2023/02/26")),
        React.createElement("div", null, dataList.map(function (item, i) {
            return React.createElement("div", { key: i, className: 'sociaty-data-list' },
                React.createElement("div", { className: 'list-item' },
                    i === 0 ? React.createElement("span", null, "\u63A8\u5E7F\u4EBA\u624B\u673A\u53F7") : '',
                    React.createElement("span", { className: 'list-item-content' }, item.subUserPhoneNum)),
                React.createElement("div", { className: 'list-item' },
                    i === 0 ? React.createElement("span", null, "\u65E5\u671F") : '',
                    React.createElement("span", { className: 'list-item-content' }, item.detailDate)),
                React.createElement("div", { className: 'list-item' },
                    i === 0 ? React.createElement("span", null, "\u6D41\u6C34\u603B\u989D") : '',
                    React.createElement("span", { className: 'list-item-content' }, (item.waterAmount / 100).toFixed(2))),
                React.createElement("div", { className: 'list-item' },
                    i === 0 ? React.createElement("span", null, "\u6536\u76CA\u603B\u989D") : '',
                    React.createElement("span", { className: 'list-item-content' }, (item.profit / 100).toFixed(2))));
        })),
        React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: hasMore }),
        React.createElement(Popup, { visible: visible, onMaskClick: function () {
                setVisible(false);
            }, className: 'sociaty-filter-popup-wrapper', closeOnMaskClick: false, bodyStyle: {
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                minHeight: '30vh',
                background: '#fff'
            } },
            React.createElement("div", { className: 'filter-conter-wrapper' },
                React.createElement("div", { className: 'filter-content-top' },
                    React.createElement("span", { onClick: function () { return setVisible(false); } }, "\u53D6\u6D88"),
                    React.createElement("span", { onClick: searchHandle }, "\u786E\u5B9A")),
                React.createElement(Form, { layout: 'horizontal', form: form, requiredMarkStyle: 'none' },
                    React.createElement(Form.Item, { label: '\u5F00\u59CB\u65F6\u95F4', name: 'startTime', rules: [{ required: true }] },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165', onFocus: function () {
                                setDatePickerVisible(true);
                                setStartFlag(true);
                            } })),
                    React.createElement(Form.Item, { label: '\u7ED3\u675F\u65F6\u95F4', name: 'endTime', rules: [{ required: true }] },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165', onFocus: function () {
                                setDatePickerVisible(true);
                                setEndFlag(true);
                            } }))))),
        React.createElement(DatePicker, { visible: datePickerVisible, onClose: dataPickerClose, precision: 'day', onConfirm: function (val) {
                dataPickerConfirm(val);
            } }));
};
export default BigProxy;
