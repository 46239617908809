var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _this = this;
import React, { useContext, useEffect, useState } from 'react';
import { Mask, Toast, Dialog } from 'antd-mobile';
import { Context, setViewModal } from '../../store';
import Api from 'src/apis';
import { stringifyParams } from 'src/utils';
// import Openwechat from './openwechat'
import './index.less';
var keyMap = {
    3: 'https://cdn.tuanzhzh.com/doubi-image/tong-yaoshi.png',
    4: 'https://cdn.tuanzhzh.com/doubi-image/yin-yaoshi.png',
    5: 'https://cdn.tuanzhzh.com/doubi-image/jin-yaoshi.png'
};
var RechargeKey = function () {
    var _a, _b;
    var _c = useState(-1), active = _c[0], setActive = _c[1];
    var _d = useState(false), payVisible = _d[0], setPayVisible = _d[1];
    var _e = useState({}), keysListInfo = _e[0], setKeysListInfo = _e[1];
    var _f = useState({}), rechargeKeyInfo = _f[0], setRechargeKeyInfo = _f[1];
    var _g = useState(''), htmlText = _g[0], setHtmlText = _g[1];
    var _h = useContext(Context), state = _h.state, dispatch = _h.dispatch;
    var viewModal = state.viewModal;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var closeModal = function () {
        dispatch(setViewModal(__assign(__assign({}, viewModal), { visible: false })));
    };
    // 判断微信环境
    // const isWeixin = () => {
    //   const ua = navigator.userAgent.toLowerCase()
    //   const match = ua.match(/MicroMessenger/i)
    //   if (match?.includes('micromessenger')) {
    //     return true
    //   }
    //   return false
    // }
    var closePay = function () {
        setPayVisible(false);
    };
    var selectItem = function (index, num) {
        setActive(index);
        setPayVisible(true);
        setRechargeKeyInfo({
            keyCountInfo: {
                keyType: viewModal.type,
                keyCount: num * 1
            }
        });
    };
    var aliPay = function () {
        // setHtmlText('')
        // console.log(document.forms[0].submit())
        Toast.show({
            icon: 'loading',
            content: '加载中…'
        });
        Api.prepareAliPay(__assign({ userId: userInfo.userId }, rechargeKeyInfo)).then(function (res) {
            var _a;
            console.log(res.data);
            setHtmlText((_a = res.data.payForm) !== null && _a !== void 0 ? _a : '');
            document.forms[0].submit();
        }, function () {
            Toast.show({
                content: '支付异常，稍后重试'
            });
        }).finally(function () {
            Toast.clear();
        });
    };
    // 获取钥匙列表
    var rechargeKeysList = function () {
        Api.rechargeKeysList({
            keyType: viewModal.type
        }).then(function (res) {
            var _a, _b;
            var tempArr = ((_a = res.data) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.keyType === viewModal.type; })) || [];
            setKeysListInfo((_b = tempArr[0]) !== null && _b !== void 0 ? _b : {});
        });
    };
    // 微信支付
    var wechatPay = function () {
        Toast.show({
            icon: 'loading',
            content: '加载中…'
        });
        var str = stringifyParams({
            keyType: rechargeKeyInfo.keyCountInfo.keyType,
            keyCount: rechargeKeyInfo.keyCountInfo.keyCount,
            userId: userInfo.userId,
            from: 'h5'
        });
        setTimeout(function () {
            Dialog.confirm({
                content: '您是否已支付完成？',
                bodyStyle: {
                    background: '#fff'
                }
            }).then(function (res) {
                if (res) {
                    window.location.reload();
                }
                else {
                    console.log('点击取消');
                }
            });
        }, 1500);
        // 判断是否是小程序环境
        if (window.__wxjs_environment === 'miniprogram') {
            window.wx.miniProgram.navigateTo({
                url: "/pages/index/index?".concat(str)
            });
        }
        else {
            getWeixinUrl();
        }
    };
    var getWeixinUrl = function () {
        var str = stringifyParams({
            keyType: rechargeKeyInfo.keyCountInfo.keyType,
            keyCount: rechargeKeyInfo.keyCountInfo.keyCount,
            userId: userInfo.userId,
            from: 'h5'
        });
        Api.createMiniProgram({
            path: '/pages/index/index',
            'env_version': 'release',
            query: str
        }).then(function (res) {
            if (!res.code) {
                closePay();
                closeModal();
                window.location.href = res.data;
            }
            else {
                Toast.show({
                    content: '支付异常，稍后重试'
                });
            }
        }).catch(function () {
            Toast.show({
                content: '支付异常，稍后重试'
            });
        }).finally(function () {
            Toast.clear();
        });
    };
    useEffect(function () {
        if (viewModal.visible) {
            setActive(-1);
            rechargeKeysList();
        }
    }, [viewModal.visible]);
    useEffect(function () {
        if (viewModal.visible) {
            setTimeout(function () {
                var btn = document.getElementById('launch-btn');
                btn.addEventListener('launch', function (e) {
                    console.log(e);
                });
                btn.addEventListener('error', function (e) {
                    console.log('fail', e.detail);
                });
            }, 1000);
        }
    }, [viewModal.visible]);
    return React.createElement("div", { className: 'recharge-key-wrapper' },
        React.createElement(Mask, { visible: viewModal.visible, opacity: 0.8 },
            React.createElement("div", { className: 'recharge-key-content' },
                React.createElement("img", { onClick: closeModal, className: 'recharge-key-close-icon', src: 'https://cdn.tuanzhzh.com/doubi-image/close-modal-icon.png' }),
                React.createElement("div", { className: 'recharge-key-title' },
                    React.createElement("span", { className: 'recharge-name' }, "\u6700\u9AD8\u53EF\u591A\u8D60"),
                    React.createElement("span", { className: 'recharge-key-gift-num' }, (_a = keysListInfo.extraKeyMaxAllCount) !== null && _a !== void 0 ? _a : 0),
                    React.createElement("img", { className: 'recharge-key-icon', src: keyMap[viewModal.type] })),
                React.createElement("div", { className: 'recharge-key-item-content' }, (_b = keysListInfo.extraKeyInfoList) === null || _b === void 0 ? void 0 : _b.map(function (item, i) {
                    return React.createElement("div", { key: i, className: 'key-item-wrapper' },
                        React.createElement("div", { onClick: selectItem.bind(_this, i, item.keyCount), className: active === i ? 'key-item active' : 'key-item' },
                            React.createElement("img", { className: 'key-item-icon', src: keyMap[viewModal.type] }),
                            React.createElement("span", { className: 'key-item-num' },
                                item.allKeyCount,
                                "\u628A")),
                        React.createElement("div", { className: 'key-item-gift-text' },
                            React.createElement("span", null, "\u5DF2\u8D60\u9001"),
                            React.createElement("span", null,
                                item.extraRatio,
                                "%")),
                        React.createElement("div", { className: 'key-item-gift-money' },
                            (item.price / 100).toFixed(2),
                            "\u5143"));
                })),
                React.createElement("div", { className: 'recharge-key-bottom-show' },
                    "\u60A8\u5F53\u524D\u6709",
                    React.createElement("span", null, viewModal.num),
                    "\u628A",
                    React.createElement("img", { className: 'recharge-key-icon bottom-show-key', src: keyMap[viewModal.type] })))),
        React.createElement(Mask, { visible: payVisible, opacity: 0.8 },
            React.createElement("div", { className: 'pay-content' },
                React.createElement("img", { onClick: closePay, className: 'pay-close-icon', src: 'https://cdn.tuanzhzh.com/doubi-image/close-modal-icon.png' }),
                React.createElement("div", { className: 'pay-title' }, "\u8BF7\u9009\u62E9\u652F\u4ED8\u65B9\u5F0F"),
                React.createElement("div", { className: 'pay-sub-title' },
                    React.createElement("span", null, "\u652F\u4ED8\u5B8C\u6210\u540E\u8BF7\u624B\u52A8\u8FD4\u56DE\u6296\u76D2\u5B50\u9875\u9762\u5E76\u5237\u65B0")),
                React.createElement("div", { className: 'pay-way-content' },
                    React.createElement("div", { className: 'pay-way-item pay-way-item-wechat', onClick: wechatPay },
                        React.createElement("img", { src: 'https://cdn.tuanzhzh.com/doubi-image/wechat.png' }),
                        React.createElement("span", { className: 'pay-item-text' }, "\u5FAE\u4FE1\u652F\u4ED8")),
                    React.createElement("div", { className: 'pay-way-item', onClick: aliPay },
                        React.createElement("img", { src: 'https://cdn.tuanzhzh.com/doubi-image/alipay.png' }),
                        React.createElement("span", { className: 'pay-item-text' }, "\u652F\u4ED8\u5B9D\u652F\u4ED8")))),
            React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlText } })));
};
export default RechargeKey;
