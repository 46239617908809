import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.less';
var Copyright = function () {
    var _a = useState(false), showHistoryRecord = _a[0], setShowHistoryRecord = _a[1];
    var location = useLocation();
    var navigate = useNavigate();
    var showHistoryRecordHandle = function () {
        navigate({
            pathname: '/history',
            search: window.location.search
        });
    };
    useEffect(function () {
        if (location.pathname === '/extract') {
            setShowHistoryRecord(true);
        }
        else {
            setShowHistoryRecord(false);
        }
    }, [location.pathname]);
    return React.createElement("div", { className: 'copy-right' },
        React.createElement("div", { className: 'copy-right-wrapper' },
            React.createElement("span", null, "Copyright"),
            showHistoryRecord && React.createElement("span", { className: 'history-record', onClick: showHistoryRecordHandle }, "\u5386\u53F2\u8BB0\u5F55")));
};
export default Copyright;
