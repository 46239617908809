import React, { useCallback, useContext } from 'react';
// import TopRolling from '@pages/Component/TopRolling'
import { NavBar, CapsuleTabs } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import LoginForm from '@pages/Component/LoginForm';
import RegisteredForm from '@pages/Component/RegisteredForm';
import { Context, setLoginCurrentKey } from 'src/store';
import './index.less';
var Login = function () {
    // const [currentKey, setCurrentKey] = useState('login')
    var _a = useContext(Context), state = _a.state, dispatch = _a.dispatch;
    var loginCurrentKey = state.loginCurrentKey;
    var navigate = useNavigate();
    var componentMap = {
        login: React.createElement(LoginForm, null),
        registered: React.createElement(RegisteredForm, null)
    };
    var back = useCallback(function () {
        navigate({
            pathname: '/home',
            search: window.location.search
        });
    }, []);
    var tabChangeHandle = useCallback(function (key) {
        dispatch(setLoginCurrentKey(key));
    }, []);
    return React.createElement("div", { className: 'login-wrapper' },
        React.createElement(NavBar, { onBack: back, back: '\u8FD4\u56DE' }),
        React.createElement(CapsuleTabs, { className: 'login-btn-wrapper', onChange: tabChangeHandle, activeKey: loginCurrentKey },
            React.createElement(CapsuleTabs.Tab, { title: '\u767B\u5F55', key: 'login' }),
            React.createElement(CapsuleTabs.Tab, { title: '\u6CE8\u518C', key: 'registered' })),
        componentMap[loginCurrentKey]);
};
export default Login;
