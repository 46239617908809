var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback } from 'react';
import { Form, Input, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import Apis from 'src/apis';
import './index.less';
var ExtensionLogin = function () {
    var form = Form.useForm()[0];
    var navigate = useNavigate();
    var loginHandle = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var obj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.validateFields()];
                case 1:
                    obj = _a.sent();
                    Apis.login(obj).then(function (res) {
                        if (res.data.loginResult) {
                            window.localStorage.setItem('system-user', JSON.stringify(res.data.user || {}));
                            // dispatch(setUserId(res.data.user.userId))
                            navigate({
                                pathname: '/extension-home',
                                search: window.location.search
                            });
                        }
                        else {
                            Toast.show({
                                icon: 'fail',
                                content: res.data.loginMsg
                            });
                        }
                    });
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var resetHandle = function () {
        form.resetFields();
    };
    return React.createElement("div", { className: 'login-form-wrapper' },
        React.createElement("div", { className: 'extension-login-title' }, "\u6296\u76D2\u5B50\u63A8\u5E7F\u7CFB\u7EDF"),
        React.createElement(Form, { layout: 'horizontal', form: form },
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u624B\u673A\u53F7"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { noStyle: true, name: 'phoneNum' },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u516511\u4F4D\u624B\u673A\u53F7', clearable: true, className: 'input-style' })))),
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u5BC6\u7801"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { name: 'passwd' },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u5BC6\u7801', clearable: true, type: 'password', className: 'input-style' })))),
            React.createElement("div", { className: 'login-item login-item-btn-wrapper' },
                React.createElement("span", { className: 'btn-style', onClick: resetHandle }, "\u91CD\u7F6E"),
                React.createElement("span", { className: 'btn-style', onClick: loginHandle }, "\u767B\u5F55"))));
};
export default ExtensionLogin;
