import srcConfig from '../requestConfig';
import { getRequestsByRoot } from 'axios-service';
var root = srcConfig.APIS.root;
var _a = getRequestsByRoot({ root: root }), post = _a.post, get = _a.get;
/**
 * @overview 如果系统有统一的错误信息处理，请将 @errorMessageDecorator 注释
 */
var Apis = /** @class */ (function () {
    function Apis() {
        /**
         * 获取验证码
         */
        this.getVerifyCode = post('/api/mystery/user/register/phone/verifyCode');
        /**
         * 注册
         */
        this.registerFromPhone = post('/api/mystery/user/register/phone');
        /**
         * 登录
         */
        this.login = post('/api/mystery/user/login/phone');
        /**
         * 用户-找回密码
         */
        this.getBackPwd = post('/api/mystery/user/password/reset');
        /**
         * 用户-钥匙数量信息
         */
        this.getKeyMessage = get('/api/mystery/user/keyInfo');
        /**
         * 用户-抖币信息
         */
        this.getDymoney = get('/api/mystery/user/dymoney');
        /**
         * 【首页】飘屏
         */
        this.getFloatScreen = get('/api/mystery/announce/floatScreen');
        /**
         * 【首页】公屏
         */
        this.getCommonScreen = get('/api/mystery/announce/commonScreen');
        /**
         * 【首页】盒子-开
         */
        this.lotteryDraw = post('/api/mystery/box/lottery/draw');
        /**
         * 获取钥匙数量
         */
        this.getKeys = get('/api/mystery/user/keyInfo');
        /**
         * 【抖币】抖币-提取记录
         */
        this.getCoinHostory = post('/api/mystery/dymoney/draw/history');
        /**
         * 【用户】用户-抖币信息
         */
        this.getCoinInfo = get('/api/mystery/user/dymoney');
        /**
         * 【抖币】抖币-提取
         */
        this.coinExtract = post('/api/mystery/dymoney/draw');
        /**
         * 【首页】钥匙-充值-钥匙信息列表
         */
        this.rechargeKeysList = get('/api/mystery/key/deposit/list');
        /**
         * 【首页】钥匙-充值-预支付-微信
         */
        this.prepareWx = post('/api/mystery/key/deposit/prepare/wx');
        /**
         * 【支付】钥匙-充值-预支付-支付宝
         */
        this.prepareAliPay = post('/api/mystery/key/deposit/prePay/ali');
        /**
         * 【抖币】抖币提取-抖音号记录
         */
        this.getDyIds = get('/api/mystery/dymoney/draw/history/dyId');
        /**
         * 【盒子】盒子-记录
         */
        this.getLotteryHistory = post('/api/mystery/box/lottery/history');
        /**
         * 【用户代理】推广员个人信息
         */
        this.getProxyUser = get('/api/mystery/proxyUser/detail');
        /**
         * 【用户代理】续期
         */
        this.userRenewal = post('/api/mystery/proxyUser/renewal');
        /**
         * 【用户代理】修改支付宝
         */
        this.modifyAlipay = post('/api/mystery/proxyUser/alipay/modify');
        /**
         * 【用户代理】注册新的推广员
         */
        this.proxyUserRegister = post('/api/mystery/proxyUser/register');
        /**
         * 【用户代理】下级代理用户数据检索
         */
        this.getSubList = post('/api/mystery/proxyUser/sub/search');
        /**
         * 【用户代理】推广员个人销售数据
         */
        this.getSaleData = get('/api/mystery/proxyUser/saleData');
        /**
         * 【首页】兑换钥匙
         */
        this.exchargeCode = post('/api/mystery/key/exchange');
        /**
         * 【小程序】生成跳转小程序链接
         */
        this.createMiniProgram = post('/api/mystery/mini/url');
        /**
         * 获取首页公告
         */
        this.getNotice = get('/api/mystery/announce/homePage');
        /**
         * 获取用户信息
         */
        this.getUserInfo = get('/api/mystery/user/detail');
    }
    return Apis;
}());
export var ServiceApis = Apis;
export default new Apis();
