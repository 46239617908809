import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Space } from 'antd-mobile';
import './index.less';
var phoneHead = [
    130, 131, 132, 155, 156, 185, 186, 176,
    175, 134, 135, 136, 137, 138, 139, 147, 150, 151, 152,
    157, 158, 159, 182, 183, 187, 188, 133, 153, 180, 189,
    192
];
var giftMap = [{
        img: 'https://cdn.tuanzhzh.com/doubi-image/tangguofeichuang.png',
        value: '小飞船'
    }, {
        img: 'https://cdn.tuanzhzh.com/doubi-image/huojian-new.png',
        value: '小火箭'
    }, {
        img: 'https://cdn.tuanzhzh.com/doubi-image/jianianhua-new.png',
        value: '小嘉年华'
    }];
function rand(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}
var interval = {
    _active: new Set(),
    _id: 0,
    set: function (callback, delay) {
        var _this = this;
        var id = this._id++;
        this._active.add(id);
        var handler = function () {
            if (!_this._active.has(id)) {
                return;
            }
            setTimeout(function () {
                callback();
                // handler()
            }, delay);
        };
        handler();
        return id;
    },
    clear: function (id) {
        this._active.delete(id);
    }
};
var GiftBroadcast = function (props) {
    var _a = useState(false), flag = _a[0], setFlag = _a[1];
    var _b = useState(), iphoneStart = _b[0], setIphoneStart = _b[1];
    var _c = useState(), iphoneEnd = _c[0], setIphoneEnd = _c[1];
    var _d = useState(), giftInfo = _d[0], setGiftInfo = _d[1];
    var creatTimeRef = useRef();
    var clearTimeRef = useRef();
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var showCurrentUser = props.showCurrentUser;
    var closeGift = function () {
        var dom = document.getElementById('gift_id');
        dom.classList.add('hide-dh');
        dom.classList.remove('show-dh');
        setFlag(false);
    };
    var randomHandle = useCallback(function () {
        creatTimeRef.current = interval.set(function () {
            var dom = document.getElementById('gift_id');
            dom.classList.remove('hide-dh');
            dom.classList.add('show-dh');
            var start = phoneHead[Math.floor((Math.random() * phoneHead.length))];
            var end = rand(1000, 9999);
            var obj = giftMap[Math.floor((Math.random() * giftMap.length))];
            setIphoneStart(start);
            setIphoneEnd(end);
            setGiftInfo(obj);
            setFlag(true);
        }, rand(15, 30) * 1000);
    }, []);
    var closeCurrentUserBroadcast = function () {
        setTimeout(function () {
            closeGift();
            randomHandle();
        }, 3000);
    };
    useEffect(function () {
        if (flag) {
            clearTimeRef.current = interval.set(function () {
                interval.clear(creatTimeRef.current);
                interval.clear(clearTimeRef.current);
                closeGift();
                randomHandle();
            }, 3000);
        }
    }, [flag]);
    useEffect(function () {
        if ((showCurrentUser === null || showCurrentUser === void 0 ? void 0 : showCurrentUser.value) && userInfo) {
            interval.clear(creatTimeRef.current);
            interval.clear(clearTimeRef.current);
            setTimeout(function () {
                var dom = document.getElementById('gift_id');
                dom.classList.remove('hide-dh');
                dom.classList.add('show-dh');
                setIphoneStart(userInfo.phoneNum.slice(0, 3));
                setIphoneEnd(userInfo.phoneNum.slice(7));
                setGiftInfo(showCurrentUser);
                setFlag(true);
                closeCurrentUserBroadcast();
            }, 2000);
        }
    }, [showCurrentUser]);
    useEffect(function () {
        randomHandle();
        return function () {
            interval.clear(creatTimeRef.current);
            interval.clear(clearTimeRef.current);
        };
    }, []);
    return React.createElement("div", { className: 'gift-broadcast-wrapper', id: 'gift_id' },
        React.createElement("div", { className: 'gift-left' },
            React.createElement(Space, { direction: 'vertical', block: true },
                React.createElement("span", { className: 'gift-left-top' },
                    React.createElement("label", { className: 'gift-title' }, "\u606D\u559C"),
                    React.createElement("label", { className: 'gift-price' },
                        iphoneStart || phoneHead[5],
                        "****",
                        iphoneEnd || '328')),
                React.createElement("span", null,
                    React.createElement("label", { className: 'gift-title' }, "\u5F00\u51FA\u4E86"),
                    React.createElement("label", { className: 'gift-price' }, (giftInfo === null || giftInfo === void 0 ? void 0 : giftInfo.value) || giftMap[1].value)))),
        React.createElement("img", { className: 'gift-img', src: (giftInfo === null || giftInfo === void 0 ? void 0 : giftInfo.img) || giftMap[1].img }));
};
export default GiftBroadcast;
