var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _this = this;
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
// import TopRolling from '@pages/Component/TopRolling'
import { useNavigate } from 'react-router-dom';
import { NavBar, Modal, Input, List, InfiniteScroll, Toast } from 'antd-mobile';
// import { sleep } from 'antd-mobile/es/utils/sleep'
import Api from 'src/apis';
import moment from 'moment';
import CustomServiceModal from '../Component/CustomServiceModal';
import { Context, setCustomServiceModal } from '../../store';
import GiftBroadcast from '@pages/Component/GiftBroadcast';
import './index.less';
var showText = {
    1: '提取中',
    2: '已完成',
    3: '已退回'
};
var cacheDyIds = [];
var DoubiExtract = function () {
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState(false), confirmModalVisible = _b[0], setConfirmModalVisible = _b[1];
    var _c = useState([]), data = _c[0], setData = _c[1];
    var _d = useState(true), hasMore = _d[0], setHasMore = _d[1];
    var _e = useState(0), coinNum = _e[0], setCoinNum = _e[1];
    var _f = useState(''), extractNum = _f[0], setEextractNum = _f[1];
    var _g = useState(''), douyin = _g[0], setDouyin = _g[1];
    var _h = useState([]), dyIds = _h[0], setDyIds = _h[1];
    var _j = useState(false), showDyIds = _j[0], setShowDyIds = _j[1];
    // const [customerVisible, setCustomerVisible] = useState(false)
    var dispatch = useContext(Context).dispatch;
    var numRef = useRef(1);
    var isLoad = useRef(true);
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var navigate = useNavigate();
    // let number = 1
    var closeModal = function () {
        setVisible(false);
    };
    var back = useCallback(function () {
        navigate({
            pathname: '/home',
            search: window.location.search
        });
    }, []);
    // 抖币提取
    var getCoinHistory = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!isLoad.current)
                return [2 /*return*/];
            isLoad.current = false;
            Api.getCoinHostory({
                page: numRef.current,
                pageSize: 10,
                userId: userInfo.userId
            }).then(function (res) {
                var _a, _b;
                setData(function (val) {
                    var temArr = __spreadArray(__spreadArray([], val, true), res.data, true);
                    temArr.forEach(function (item) {
                        item.drawTime = moment(item.lotteryTime).format('YYYY-MM-DD HH:mm:ss');
                    });
                    return __spreadArray(__spreadArray([], val, true), res.data, true);
                });
                setHasMore(((_a = res.data) === null || _a === void 0 ? void 0 : _a.length) > 0);
                if ((_b = res.data) === null || _b === void 0 ? void 0 : _b.length) {
                    numRef.current += 1;
                }
            }, function (err) {
                Toast.show({
                    content: err.err_msg
                });
            }).finally(function () {
                isLoad.current = true;
            });
            return [2 /*return*/];
        });
    }); };
    function loadMore() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getCoinHistory()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    var contactCustomService = function () {
        dispatch(setCustomServiceModal({
            visible: true
        }));
    };
    var homeBack = function () {
        navigate({
            pathname: '/home',
            search: window.location.search
        });
    };
    var extractHandle = function () {
        // if (!coinNum) {
        //   return Toast.show({
        //     content: <span>当前抖币数量为 <label style={{ color: 'red' }}>0</label></span>
        //   })
        // }
        if (!userInfo.userId) {
            return Toast.show({
                content: '请先登录',
                duration: 2000
            });
        }
        setVisible(true);
    };
    var coinChangeHandle = function (val) {
        setEextractNum(val);
    };
    var douYinChangeHandle = function (val) {
        setDouyin(val);
        var tempArr = cacheDyIds.filter(function (item) { return item.indexOf(val) !== -1; });
        setDyIds(tempArr);
    };
    // 抖币提取
    var coinExtract = function () {
        if ((extractNum * 1) % 10 !== 0 || extractNum * 1 > 50000) {
            Toast.show({
                content: '请输入10的整数倍,最大50000',
                duration: 2000
            });
            return;
        }
        if (!extractNum || !douyin) {
            Toast.show({
                content: '抖币数量或抖音号为空',
                duration: 2000
            });
            return;
        }
        setConfirmModalVisible(true);
    };
    var suerHandle = function () {
        setConfirmModalVisible(false);
        Toast.show({
            icon: 'loading',
            content: '加载中…'
        });
        Api.coinExtract({
            userId: userInfo.userId,
            drawDyMoneyAmount: extractNum * 1,
            dyId: douyin
        }).then(function (res) {
            if (!res.data.isDrawEnable) {
                Toast.show({
                    content: res.data.drawFailedMsg
                });
            }
            else {
                Toast.clear();
                Toast.show({
                    content: '提取申请将在1小时内完成',
                    duration: 2000
                });
                // number = 1
                numRef.current = 1;
                setData([]);
                getCoinInfo();
                getCoinHistory();
                setVisible(false);
                // setTimeout(() => {
                //   setCustomerVisible(true)
                // }, 500);
            }
        }, function (err) {
            Toast.show({
                content: err.drawFailedMsg
            });
            setTimeout(function () {
                Toast.clear();
            }, 3000);
        });
    };
    // 获取抖币信息
    var getCoinInfo = function () {
        Api.getCoinInfo({
            userId: userInfo.userId
        }).then((function (res) {
            var _a;
            setCoinNum((_a = res.data.dyMoneyAmount) !== null && _a !== void 0 ? _a : 0);
        }), function (err) {
            Toast.show({
                content: err.msg
            });
        });
    };
    // 抖音号记录
    var getDyIds = function () {
        Api.getDyIds({
            userId: userInfo.userId
        }).then(function (res) {
            var _a, _b;
            setDyIds((_a = res.data) !== null && _a !== void 0 ? _a : []);
            cacheDyIds = (_b = res.data) !== null && _b !== void 0 ? _b : [];
        }, function () {
            Toast.show({
                content: '获取抖音号失败，请输入'
            });
        });
    };
    // 获取焦点
    var dyFocus = function () {
        setTimeout(function () {
            setShowDyIds(true);
        }, 100);
    };
    // 失去焦点
    var dyBlur = function () {
        setTimeout(function () {
            setShowDyIds(false);
        }, 0);
    };
    // 选择抖音号
    var choiceDyIds = function (val) {
        setDouyin(val);
    };
    var cancelHandle = function () {
        setConfirmModalVisible(false);
    };
    useEffect(function () {
        getCoinInfo();
    }, []);
    useEffect(function () {
        if (visible) {
            getDyIds();
            setEextractNum('');
            setDouyin('');
        }
    }, [visible]);
    return React.createElement("div", { className: 'extract-wrapper' },
        React.createElement(NavBar, { onBack: back, back: '\u8FD4\u56DE' }, "\u6296\u5E01\u63D0\u53D6"),
        React.createElement(GiftBroadcast, null),
        React.createElement("div", { className: 'extract-header-wrapper' },
            React.createElement("div", { className: 'extract-header' },
                React.createElement("div", { className: 'extract-header-top' },
                    React.createElement("span", { className: 'user-portrait' },
                        React.createElement("img", { className: 'portrait-icon', src: userInfo.avatarUrl || 'https://cdn.tuanzhzh.com/doubi-image/no-login-icon.png' })),
                    React.createElement("span", { className: 'user-name' }, userInfo.nickName || '游客')),
                React.createElement("div", { className: 'extract-header-bottom' },
                    React.createElement("span", null, "\u53EF\u63D0\u53D6\u6296\u5E01"),
                    React.createElement("span", null, coinNum))),
            React.createElement("div", { className: 'extract-btn', onClick: extractHandle },
                React.createElement("span", null, "\u63D0\u53D6"))),
        React.createElement("div", { className: 'home-back', onClick: homeBack },
            React.createElement("img", { className: 'doubi-tiqu', src: 'https://cdn.tuanzhzh.com/doubi-image/home-icon.png' }),
            React.createElement("span", { className: 'doubi-tiqu-text' }, "\u8FD4\u56DE\u9996\u9875")),
        React.createElement("div", { className: 'home-back contact-customer', onClick: contactCustomService },
            React.createElement("img", { className: 'doubi-tiqu', src: 'https://cdn.tuanzhzh.com/doubi-image/kefu.png' }),
            React.createElement("span", { className: 'doubi-tiqu-text' }, "\u8054\u7CFB\u5BA2\u670D")),
        React.createElement("div", { className: 'extract-list-wrapper' },
            React.createElement(List, null, data.map(function (item, i) {
                return React.createElement(List.Item, { key: i },
                    React.createElement("div", { className: item.status.code === 3 ? 'extract-list-item extract-list-item2' : 'extract-list-item' },
                        React.createElement("div", { className: 'extract-list-content' },
                            React.createElement("div", { className: 'list-item-top' },
                                React.createElement("span", { className: 'list-item-user' }, userInfo.nickName || '--'),
                                React.createElement("span", null,
                                    React.createElement("label", null, "\u63D0\u53D6\u6296\u5E01"),
                                    React.createElement("label", { className: 'list-item-num' }, item.drawDyMoneyAmount || 0)),
                                React.createElement("span", { className: 'list-item-zhi' }, "\u81F3"),
                                item.status.code === 3 ? React.createElement("span", { className: 'list-item-error' }, "\u8D26\u53F7\u5F02\u5E38") : ''),
                            React.createElement("div", { className: 'list-item-bottom' },
                                React.createElement("span", { className: 'list-item-time' }, item.drawTime || '--'),
                                React.createElement("span", null,
                                    React.createElement("label", null, "\u6296\u97F3"),
                                    React.createElement("label", { className: 'list-item-douyin-number' }, item.dyId || '--')),
                                React.createElement("span", { className: item.status.code === 3 ? 'list-item-operation-btn list-item-operation-btn2' : 'list-item-operation-btn' },
                                    React.createElement("label", null, showText[item.status.code]))))));
            })),
            React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: hasMore })),
        React.createElement(Modal, { bodyClassName: 'extract-modal-wrapper', visible: visible, title: React.createElement("div", { className: 'extract-modal-title' },
                React.createElement("span", null, "\u53EF\u63D0\u53D6\u6296\u5E01"),
                React.createElement("img", { className: 'icon-close', onClick: closeModal, src: 'https://cdn.tuanzhzh.com/doubi-image/close-modal-icon.png' })), content: React.createElement("div", { className: 'extract-modal-content' },
                React.createElement("div", { className: 'extract-modal-doubi-num' }, coinNum),
                React.createElement("div", { className: 'extract-modal-form' },
                    React.createElement("div", { className: 'form-item' },
                        React.createElement("span", { className: 'form-item-name' }, "\u63D0\u53D6\u6296\u5E01"),
                        React.createElement(Input, { placeholder: '10\u7684\u6574\u6570\u500D,\u6700\u592750000', className: 'form-input-style', onChange: coinChangeHandle, value: extractNum, type: 'number', clearable: true })),
                    React.createElement("div", { className: 'form-item' },
                        React.createElement("span", { className: 'form-item-name' }, "\u9009\u62E9\u6296\u97F3\u53F7"),
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u6296\u97F3\u53F7', className: 'form-input-style', clearable: true, onChange: douYinChangeHandle, onFocus: dyFocus, value: douyin, onBlur: dyBlur }),
                        dyIds.length && React.createElement("ul", { className: showDyIds ? 'form-douyin-id-ul' : 'form-douyin-id-ul hide' }, dyIds.map(function (list, k) {
                            return React.createElement("li", { key: k, onClick: choiceDyIds.bind(_this, list) }, list);
                        })))),
                React.createElement("div", { className: 'extract-bottom-wrapper' },
                    React.createElement("div", { className: 'extract-bottom-btn', onClick: coinExtract },
                        React.createElement("span", null, "\u786E\u5B9A")),
                    React.createElement("div", { className: 'extract-bottom-text' }, "\u7CFB\u7EDF\u4F1A\u6309\u7167\u60A8\u9009\u62E9\u6216\u8F93\u5165\u7684\u6296\u97F3\u53F7\u8FDB\u884C\u5145\u503C"))), closeOnAction: true, onClose: function () {
                setVisible(false);
            } }),
        React.createElement(Modal, { bodyClassName: 'confirm-modal-wrapper', visible: confirmModalVisible, title: null, content: React.createElement("div", { className: 'confirm-modal-content' },
                React.createElement("span", { className: 'confirm-content-title' },
                    "\u60A8\u5C06\u63D0\u53D6",
                    React.createElement("label", { className: 'confirm-content-tip' }, extractNum),
                    "\u6296\u5E01\u81F3\u6296\u97F3\u53F7",
                    React.createElement("label", { className: 'confirm-content-tip' }, douyin)),
                React.createElement("span", { className: 'confirm-content-tip' }, "\u5F53\u65E5\u591A\u6B21\u5C0F\u989D\u63D0\u53D6\u6296\u5E01\u53EF\u80FD\u5BFC\u81F4\u6296\u97F3\u8D26\u53F7\u98CE\u63A7\uFF0C\u77ED\u65F6\u95F4\u5185\u65E0\u6CD5\u5145\u503C\u6296\u5E01"),
                React.createElement("div", { className: 'confirm-content-btn' },
                    React.createElement("span", { className: 'btn-cancle', onClick: cancelHandle }, "\u53D6\u6D88"),
                    React.createElement("span", { className: 'btn-sure', onClick: suerHandle }, "\u786E\u5B9A"))) }),
        React.createElement(CustomServiceModal, null));
};
export default DoubiExtract;
