var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Form, Input, Picker, Space, Toast, Modal } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import Apis from 'src/apis';
import './index.less';
var ExtensionLogin = function () {
    var _a = useState(false), aliPayCode = _a[0], setAliPayCode = _a[1];
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var _c = useState(['2']), value = _c[0], setValue = _c[1];
    var form = Form.useForm()[0];
    var navigate = useNavigate();
    var userInfo = JSON.parse(window.localStorage.getItem('system-user') || '{}');
    var loginHandle = function () { return __awaiter(void 0, void 0, void 0, function () {
        var obj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.validateFields()];
                case 1:
                    obj = _a.sent();
                    Apis.proxyUserRegister(__assign(__assign({}, obj), { subProxyUserType: Number(value[0]) })).then(function (res) {
                        if (res.data.registerSuccess) {
                            Toast.show({
                                icon: 'success',
                                content: '成功'
                            });
                            setAliPayCode(true);
                            return;
                        }
                        Toast.show({
                            icon: 'fail',
                            content: res.data.registerFailedMsg
                        });
                    }).catch(function (err) {
                        Toast.show({
                            icon: 'fail',
                            content: err
                        });
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var resetHandle = function () {
        form.resetFields();
    };
    var loginRecruitHandle = function () {
        navigate({
            pathname: '/extension-login',
            search: window.location.search
        });
    };
    var closeHandle = function () {
        setAliPayCode(false);
    };
    return React.createElement("div", { className: 'login-form-wrapper' },
        React.createElement("div", { className: 'extension-login-title' }, "\u62DB\u52DF\u65B0\u63A8\u5E7F\u5458"),
        React.createElement(Form, { layout: 'horizontal', form: form },
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u6307\u5BFC\u4EBA\u624B\u673A\u53F7"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { noStyle: true, name: 'parentProxyUserPhoneNum', initialValue: userInfo.phoneNum },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u516511\u4F4D\u624B\u673A\u53F7', disabled: true, clearable: true, className: 'input-style' })))),
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u65B0\u63A8\u5E7F\u5458\u624B\u673A\u53F7"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { noStyle: true, name: 'subProxyUserPhoneNum' },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u516511\u4F4D\u624B\u673A\u53F7', clearable: true, className: 'input-style' })))),
            React.createElement("div", { className: 'login-item', onClick: function () { return setVisible(true); } },
                React.createElement("span", { className: 'login-item-title' }, "\u65B0\u63A8\u5E7F\u5458\u7EA7\u522B"),
                React.createElement("div", { className: 'login-item-component login-item-level' },
                    React.createElement(Form.Item, { noStyle: true, name: 'subProxyUserType' },
                        React.createElement(Picker, { columns: [
                                [{
                                        label: '一级推广员',
                                        value: '1'
                                    }, {
                                        label: '二级推广员',
                                        value: '2'
                                    }]
                            ], visible: visible, onClose: function () {
                                setVisible(false);
                            }, value: value || ['请选择'], onConfirm: function (v) {
                                setValue(v);
                            }, popupClassName: 'level-popup-wrapper' }, function (items) {
                            return (React.createElement(Space, { align: 'center' }, items.every(function (item) { return item === null; })
                                ? '未选择'
                                : items.map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.label) !== null && _a !== void 0 ? _a : '未选择'; }).join(' - ')));
                        })))),
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u65B0\u63A8\u5E7F\u5458\u652F\u4ED8\u5B9D"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { noStyle: true, name: 'subProxyUserAlipayId' },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u652F\u4ED8\u5B9D\u8D26\u53F7', clearable: true, className: 'input-style' })))),
            React.createElement("div", { className: 'login-system-text', onClick: loginRecruitHandle }, "\u767B\u5F55\u63A8\u5E7F\u7CFB\u7EDF"),
            React.createElement("div", { className: 'login-item login-item-btn-wrapper' },
                React.createElement("span", { className: 'btn-style', onClick: resetHandle }, "\u91CD\u7F6E"),
                React.createElement("span", { className: 'btn-style', onClick: loginHandle }, "\u786E\u5B9A"))),
        React.createElement(Modal, { title: '', visible: aliPayCode, showCloseButton: true, onClose: closeHandle, bodyClassName: 'recruit-modal-wrapper', content: React.createElement("div", { className: 'recruit-content-wrapper' },
                React.createElement(Space, { direction: 'vertical', block: true },
                    React.createElement("span", { className: 'alipay-title' }, "\u8BF7\u7528\u652F\u4ED8\u5B9D\u626B\u7801\u652F\u4ED8"),
                    React.createElement("img", { src: 'https://cdn.tuanzhzh.com/doubi-image/alipay.jpeg' }))) }));
};
export default ExtensionLogin;
