import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '@pages/Login';
import ForgetPassword from '@pages/ForgetPassword';
import DoubiExtract from '@pages/DoubiExtract';
import History from '@pages/History';
import ExtensionLogin from '@pages/ExtensionSystem/Login';
import ExtensionHome from '@pages/ExtensionSystem/Home';
import ExtensionRecruit from '@pages/ExtensionSystem/Recruit';
import SociatySmallProxy from '@pages/ExtensionSystem/SociatySmallProxy';
import BigProxy from '@pages/ExtensionSystem/BigProxy';
var ComponentAppRoute = function () {
    var routes = useRoutes([
        { path: '/', element: React.createElement(Navigate, { to: '/home' }) },
        { path: '/home', element: React.createElement(Home, null) },
        { path: '/login', element: React.createElement(Login, null) },
        { path: '/forget', element: React.createElement(ForgetPassword, null) },
        { path: '/extract', element: React.createElement(DoubiExtract, null) },
        { path: '/history', element: React.createElement(History, null) },
        { path: '/extension-login', element: React.createElement(ExtensionLogin, null) },
        { path: '/extension-home', element: React.createElement(ExtensionHome, null) },
        { path: '/extension-recruit', element: React.createElement(ExtensionRecruit, null) },
        { path: '/extension-sociaty', element: React.createElement(SociatySmallProxy, null) },
        { path: '/extension-wild', element: React.createElement(BigProxy, null) }
    ]);
    return routes;
};
export default ComponentAppRoute;
