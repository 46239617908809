var _a;
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
export var Context = React.createContext(null);
export var initialState = {
    loginCurrentKey: 'login',
    userId: '',
    renewModalData: {
        visible: false,
        level: 0
    },
    upgradeModalData: {
        visible: false
    },
    modifyPayData: {
        visible: false,
        aliPayId: ''
    },
    customServiceModal: {
        visible: false
    }
};
export var reduxSlice = createSlice({
    name: 'reduxSlice',
    initialState: initialState,
    reducers: {
        setLoginCurrentKey: function (state, _a) {
            var payload = _a.payload;
            state.loginCurrentKey = payload;
        },
        setUserId: function (state, _a) {
            var payload = _a.payload;
            state.userId = payload;
        },
        setRenewModalData: function (state, _a) {
            var payload = _a.payload;
            state.renewModalData = payload;
        },
        setUpgradeModalData: function (state, _a) {
            var payload = _a.payload;
            state.upgradeModalData = payload;
        },
        setModifyPayData: function (state, _a) {
            var payload = _a.payload;
            state.modifyPayData = payload;
        },
        setCustomServiceModal: function (state, _a) {
            var payload = _a.payload;
            state.customServiceModal = payload;
        }
    }
});
// Action creators are generated for each case reducer function
export var setLoginCurrentKey = (_a = reduxSlice.actions, _a.setLoginCurrentKey), setUserId = _a.setUserId, setRenewModalData = _a.setRenewModalData, setUpgradeModalData = _a.setUpgradeModalData, setModifyPayData = _a.setModifyPayData, setCustomServiceModal = _a.setCustomServiceModal;
// redux-thunk actions
// 拉取是否开通接口
// export const getIsOpen =
//   (query = {}) =>
//   async (dispatch) => {
//     const res = await Apis.getIsOpen(query).catch((err) => {
//       console.log('getIsOpen err', err)
//     })
//     const isOpen = res?.data ?? false
//     dispatch(setIsOpen(isOpen))
//   }
// 数据配置：拉取数据配置列表
// export const getDataTableList = (params) => async (dispatch) => {
//   const res = await Apis.getDataTableList(params).catch((err) => {
//     console.log('getDataTableList err', err)
//     return []
//   })
//   return res?.data
// }
// Reducer
export default reduxSlice.reducer;
