import React, { useContext, useState } from 'react';
import { Modal, Space, Input, Toast } from 'antd-mobile';
import { Context, setModifyPayData } from '../../../../store';
import Apis from 'src/apis';
import './index.less';
var ModifyPay = function () {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var _b = useContext(Context), state = _b.state, dispatch = _b.dispatch;
    var userInfo = JSON.parse(window.localStorage.getItem('system-user') || '{}');
    var close = function () {
        dispatch(setModifyPayData({
            visible: false
        }));
    };
    var modifySureHandle = function () {
        Apis.modifyAlipay({
            userId: userInfo.userId,
            newAlipayId: value
        }).then(function (res) {
            if (res.data.modifySuccess) {
                Toast.show({
                    icon: 'success',
                    content: '修改成功'
                });
                dispatch(setModifyPayData({
                    visible: false,
                    aliPayId: value
                }));
                return;
            }
            Toast.show({
                icon: 'fail',
                content: res.data.modifyFailedMsg
            });
        }).catch(function () {
            Toast.show({
                icon: 'fail',
                content: '修改报错'
            });
        });
    };
    return React.createElement(Modal, { title: '\u652F\u4ED8\u5B9D\u8D26\u53F7\u4FEE\u6539', visible: state.modifyPayData.visible, showCloseButton: true, onClose: close, bodyClassName: 'modify-pay-modal-wrapper', content: React.createElement("div", { className: 'modify-pay-content-wrapper' },
            React.createElement(Space, { direction: 'vertical', block: true },
                React.createElement("span", null,
                    React.createElement("label", null, "\u60A8\u5F53\u524D\u7684\u7ED3\u7B97\u652F\u4ED8\u5B9D\u4E3A\uFF1A"),
                    React.createElement("label", { className: 'item-value' }, state.modifyPayData.aliPayId)),
                React.createElement("span", null, "\u8BF7\u8F93\u5165\u65B0\u7684\u7ED3\u7B97\u652F\u4ED8\u5B9D\uFF1A"),
                React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u5185\u5BB9', value: value, className: 'pay-input', onChange: function (val) {
                        setValue(val);
                    } }),
                React.createElement("div", { onClick: modifySureHandle, className: 'modify-btn' }, "\u786E\u5B9A"))) });
};
export default ModifyPay;
