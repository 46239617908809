var _this = this;
import React, { useContext, useEffect, useState } from 'react';
import { Divider, Space, Ellipsis, Toast, Empty } from 'antd-mobile';
import { Context, setRenewModalData, setUpgradeModalData, setModifyPayData } from '../../../store';
import RenewModal from './RenewModal';
import UpgradeModal from './UpgradeModal';
import ModifyPay from './ModifyPay';
import Apis from 'src/apis';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import './index.less';
var levelMap = {
    1: '一级推广',
    2: '二级推广'
};
var ExtensionHome = function () {
    var _a = useState({}), extensionData = _a[0], setExtensionData = _a[1];
    var _b = useContext(Context), state = _b.state, dispatch = _b.dispatch;
    var navigate = useNavigate();
    var userInfo = JSON.parse(window.localStorage.getItem('system-user') || '{}');
    // const copyToClipboard = (textToCopy: any) => {
    //   // navigator clipboard 需要https等安全上下文
    //   if (navigator.clipboard && window.isSecureContext) {
    //     // navigator clipboard 向剪贴板写文本
    //     Toast.show({
    //       icon: 'success',
    //       content: '复制成功'
    //     })
    //     return navigator.clipboard.writeText(textToCopy);
    //   } else {
    //     // 创建text area
    //     const textArea = document.createElement('textarea');
    //     textArea.value = textToCopy;
    //     // 使text area不在viewport，同时设置不可见
    //     textArea.style.position = 'absolute';
    //     textArea.style.opacity = '0';
    //     textArea.style.left = '999999px';
    //     textArea.style.top = '-999999px';
    //     document.body.appendChild(textArea);
    //     textArea.focus();
    //     textArea.select();
    //     return new Promise((resolve, reject) => {
    //       // 执行复制命令并移除文本框
    //       // eslint-disable-next-line prefer-promise-reject-errors
    //       document.execCommand('copy') ? resolve('') : reject();
    //       textArea.remove();
    //       Toast.show({
    //         icon: 'success',
    //         content: '复制成功'
    //       })
    //     });
    //   }
    // }
    var copyToClipboard = function (url) {
        copy(url);
        Toast.show({
            icon: 'success',
            content: '复制成功'
        });
    };
    var renewHandle = function (level) {
        dispatch(setRenewModalData({
            visible: true,
            level: level
        }));
    };
    var upgradeHandle = function () {
        dispatch(setUpgradeModalData({
            visible: true
        }));
    };
    var modifyPayHandle = function (aliPayId) {
        dispatch(setModifyPayData({
            visible: true,
            aliPayId: aliPayId
        }));
    };
    var getProxyUser = function () {
        Apis.getProxyUser({
            userId: userInfo.userId // '1588476545ab4a3b9f18833dba5dbb1f' userInfo.userId
        }).then(function (res) {
            Object.keys(res.data || {}).forEach(function (item) {
                if (['curDateProfit', 'nextMonthProfit', 'historyAllProfit'].includes(item)) {
                    res.data[item] = Number(res.data[item] / 100).toFixed(2);
                }
            });
            setExtensionData(res.data || {});
        });
    };
    var recruitHandle = function () {
        navigate({
            pathname: '/extension-recruit',
            search: window.location.search
        });
    };
    var logout = function () {
        window.localStorage.removeItem('system-user');
        navigate({
            pathname: '/extension-login',
            search: window.location.search
        });
    };
    var showDetail = function () {
        navigate({
            pathname: '/extension-wild',
            search: window.location.search
        });
    };
    var extensionLogin = function () {
        navigate({
            pathname: '/extension-login',
            search: window.location.search
        });
    };
    useEffect(function () {
        getProxyUser();
    }, []);
    return React.createElement("div", { className: 'extension-home-wrapper' },
        React.createElement("div", { className: 'extension-home-title' }, "\u63A8\u5E7F\u540E\u53F0"),
        React.createElement(Empty, { style: { display: userInfo.userId ? 'none' : '' }, description: React.createElement("span", { className: 'extension-login-btn', onClick: extensionLogin }, "\u70B9\u51FB\u767B\u5F55") }),
        React.createElement("div", { style: { display: userInfo.userId ? '' : 'none' } },
            React.createElement("div", { className: 'extension-home-date' },
                React.createElement("span", null,
                    moment(extensionData === null || extensionData === void 0 ? void 0 : extensionData.expDate).format('YYYY/MM/DD'),
                    " \u5230\u671F"),
                React.createElement("span", { className: 'extension-home-date-btn', onClick: renewHandle.bind(_this, extensionData.proxyUserType) }, "\u7EED\u671F")),
            React.createElement(Divider, null),
            React.createElement("div", { className: 'extension-home-profit-wrapper' },
                React.createElement(Space, { direction: 'vertical' },
                    React.createElement("div", { className: 'extension-home-profit-item' },
                        React.createElement("span", null, "\u5F53\u65E5\u6536\u76CA"),
                        React.createElement("span", null, extensionData.curDateProfit)),
                    React.createElement("div", { className: 'extension-home-profit-item' },
                        React.createElement("span", null, "\u4E0B\u67085\u65E5\u5C06\u7ED3\u7B97"),
                        React.createElement("span", null, extensionData.nextMonthProfit)),
                    React.createElement("div", { className: 'extension-home-profit-item' },
                        React.createElement("span", null, "\u5386\u53F2\u603B\u6536\u76CA"),
                        React.createElement("span", null, extensionData.historyAllProfit),
                        extensionData.hasDetailSaleData ? React.createElement("span", { onClick: showDetail }, "\u63A8\u5E7F\u660E\u7EC6") : ''))),
            React.createElement(Divider, null),
            React.createElement("div", { className: 'extension-home-user-wrapper' },
                React.createElement(Space, { direction: 'vertical' },
                    React.createElement("div", { className: 'extension-home-user-item' },
                        React.createElement("span", null, "\u7528\u6237\u540D\u79F0"),
                        React.createElement("span", null, extensionData.userPhoneNum),
                        React.createElement("span", { onClick: logout }, "\u9000\u51FA")),
                    React.createElement("div", { className: 'extension-home-user-item' },
                        React.createElement("span", null, "\u652F\u4ED8\u5B9D"),
                        React.createElement("span", null, state.modifyPayData.aliPayId || extensionData.aliPayId),
                        React.createElement("span", { onClick: modifyPayHandle.bind(_this, extensionData.aliPayId) }, "\u4FEE\u6539")),
                    React.createElement("div", { className: 'extension-home-user-item' },
                        React.createElement("span", null, "\u7528\u6237\u7EA7\u522B"),
                        React.createElement("span", null, levelMap[extensionData.proxyUserType]),
                        extensionData.proxyUserType === 2 ? React.createElement("span", { onClick: upgradeHandle }, "\u5347\u7EA7") : ''),
                    React.createElement("div", { className: 'extension-home-user-item' },
                        React.createElement("span", null, "\u63D0\u6210\u6BD4\u4F8B"),
                        React.createElement("span", null,
                            extensionData.commissionRatio || 0,
                            "%")))),
            React.createElement(Divider, null),
            React.createElement("div", { className: 'extension-home-extension-user-wrapper' },
                React.createElement(Space, { direction: 'vertical' },
                    React.createElement("span", { className: 'extension-home-extension-user-title' }, "\u63A8\u5E7F\u5458\u7BA1\u7406"),
                    React.createElement("div", { className: 'extension-home-extension-user-info' },
                        React.createElement("span", null, "\u5DF2\u62DB\u52DF\u7684\u63A8\u5E7F\u5458"),
                        React.createElement("span", null,
                            extensionData.subProxyUserCount,
                            "\u4EBA"),
                        React.createElement("span", { onClick: recruitHandle }, "\u62DB\u52DF\u65B0\u63A8\u5E7F\u5458")))),
            React.createElement("div", { className: 'extension-home-extension-new-user-wrapper' },
                React.createElement("div", { className: 'extension-home-new-user-info' },
                    React.createElement("span", { className: 'extension-home-extension-user-title' }, "\u63A8\u5E7F\u65B0\u7528\u6237"),
                    React.createElement("span", null,
                        "\u5DF2\u63A8\u5E7F\u7684\u7528\u6237\uFF1A",
                        extensionData.subUserCount,
                        "\u4EBA")),
                React.createElement("div", { className: 'extension-url-link' },
                    React.createElement(Ellipsis, { direction: 'end', content: extensionData.subUserUrl })),
                React.createElement("span", { className: 'extension-url-copy', onClick: copyToClipboard.bind(_this, extensionData.subUserUrl) }, "\u590D\u5236")),
            React.createElement(RenewModal, null),
            React.createElement(UpgradeModal, null),
            React.createElement(ModifyPay, null)));
};
export default ExtensionHome;
