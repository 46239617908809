var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useContext, useState } from 'react';
import { Form, Input, Button, Toast as ShowToast } from 'antd-mobile';
import Toast from '@pages/Component/Toast';
import Apis from 'src/apis';
import { Context, setLoginCurrentKey } from 'src/store';
import { getUrlParam } from 'src/utils';
import './index.less';
var count = 60;
var RegisteredForm = function () {
    var _a = useState('获取验证码'), verificaationText = _a[0], setVerificaationText = _a[1];
    var _b = useState('账号注册成功请登录'), text = _b[0], setText = _b[1];
    var dispatch = useContext(Context).dispatch;
    var _c = useState(false), showToast = _c[0], setShowToast = _c[1];
    var form = Form.useForm()[0];
    var registerHandle = function () { return __awaiter(void 0, void 0, void 0, function () {
        var obj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.validateFields()];
                case 1:
                    obj = _a.sent();
                    Apis.registerFromPhone(__assign(__assign({}, obj), { proxyUserId: getUrlParam('proxyUserId') ? getUrlParam('proxyUserId') : '' })).then(function (res) {
                        // 注册成功，跳转到登录
                        if (res.data.registerResult) {
                            ShowToast.show({
                                icon: 'success',
                                content: '注册成功，请登录'
                            });
                            dispatch(setLoginCurrentKey('login'));
                        }
                        else {
                            setShowToast(true);
                            setText(res.data.registerMsg);
                            setTimeout(function () {
                                setShowToast(false);
                            }, 2000);
                        }
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var pkgNameRule = {
        pattern: /^1[3-9]\d{9}$/,
        message: '手机号不合法'
    };
    var passwordRule = {
        pattern: /^[a-zA-Z0-9]{8,16}$/,
        message: '8~16位数字、英文混合'
    };
    var getVerificationCode = useCallback(function () {
        if (count < 60)
            return;
        form.validateFields(['phoneNum']).then(function (res) {
            console.log(res.data);
            var timerId = null;
            verificationCode();
            setVerificaationText("".concat(count, " s"));
            timerId = setTimeout(function run() {
                if (count < 1) {
                    clearTimeout(timerId);
                    setVerificaationText('获取验证码');
                    count = 60;
                    return;
                }
                count -= 1;
                timerId = setTimeout(run, 1000);
                setVerificaationText("".concat(count, " s"));
            }, 1000);
        }, function (err) {
            console.log(err);
        });
    }, []);
    var verificationCode = function () {
        Apis.getVerifyCode({
            phoneNum: form.getFieldsValue().phoneNum
        }).then(function (res) {
            console.log(res.data);
            if (!res.data.sendResult) {
                ShowToast.show({
                    icon: 'fail',
                    content: res.data.sendMsg
                });
            }
        }, function (err) {
            ShowToast.show({
                icon: 'fail',
                content: err.sendMsg
            });
        });
    };
    return React.createElement("div", { className: 'resgister-form-wrapper' },
        React.createElement(Form, { layout: 'horizontal', form: form, requiredMarkStyle: 'none' },
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u624B\u673A\u53F7"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { name: 'phoneNum', validateTrigger: ['onBlur'], rules: [
                            { required: true, message: '请输入11位手机号' },
                            pkgNameRule
                        ] },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u516511\u4F4D\u624B\u673A\u53F7', clearable: true, className: 'input-style' })))),
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u9A8C\u8BC1\u7801"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { noStyle: true, name: 'verifyCode', rules: [{ required: true, message: '请输入验证码' }] },
                        React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u9A8C\u8BC1\u7801', clearable: true, className: 'input-style' })),
                    React.createElement(Button, { block: true, shape: 'rounded', className: 'verification-btn', onClick: getVerificationCode }, verificaationText))),
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u5BC6\u7801"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { name: 'passwd', validateTrigger: ['onBlur'], rules: [
                            { required: true, message: '8~16位数字、英文混合' },
                            passwordRule
                        ] },
                        React.createElement(Input, { type: 'password', placeholder: '8~16\u4F4D\u6570\u5B57\u3001\u82F1\u6587\u6DF7\u5408', clearable: true, className: 'input-style' })))),
            React.createElement("div", { className: 'login-item' },
                React.createElement("span", { className: 'login-item-title' }, "\u9080\u8BF7\u7801"),
                React.createElement("div", { className: 'login-item-component' },
                    React.createElement(Form.Item, { name: 'invitationCode', rules: [{ required: false, message: '请输入邀请码' }] },
                        React.createElement(Input, { placeholder: '\u9009\u586B', clearable: true, className: 'input-style' }))))),
        React.createElement("div", { className: 'login-btn login-register-btn', onClick: registerHandle },
            React.createElement("span", null, "\u6CE8\u518C")),
        React.createElement(Toast, { content: text, visible: showToast }));
};
export default RegisteredForm;
