var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from 'react';
import { Mask, Input, Button, Toast } from 'antd-mobile';
import { Context, setExchangeCodeModal, getKeys } from '../../store';
import Api from 'src/apis';
import './index.less';
var ExchangeCode = function () {
    var _a = useState(), value = _a[0], setValue = _a[1];
    var _b = useContext(Context), state = _b.state, dispatch = _b.dispatch;
    var exchangeCodeModal = state.exchangeCodeModal;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var closeModal = function () {
        dispatch(setExchangeCodeModal(__assign(__assign({}, exchangeCodeModal), { visible: false })));
    };
    var valueChange = function (e) {
        setValue(e);
    };
    var exchargeCodeHandle = function () {
        Api.exchargeCode({
            userId: userInfo.userId,
            exchangeCode: value === null || value === void 0 ? void 0 : value.trim()
        }).then(function (res) {
            if (res.data.exchangeSuccess) {
                Toast.show({
                    icon: 'success',
                    content: '兑换成功'
                });
                dispatch(getKeys({
                    userId: userInfo.userId
                }));
                dispatch(setExchangeCodeModal(__assign(__assign({}, exchangeCodeModal), { visible: false })));
            }
            else {
                Toast.show({
                    icon: 'fail',
                    content: res.data.exchangeFailedMsg
                });
            }
        });
    };
    useEffect(function () {
        if (exchangeCodeModal.visible) {
            setValue('');
        }
    }, [exchangeCodeModal.visible]);
    return React.createElement("div", { className: 'exchange-wrapper' },
        React.createElement(Mask, { visible: exchangeCodeModal.visible, opacity: 0.8 },
            React.createElement("div", { className: 'exchange-code-wrapper' },
                React.createElement("img", { onClick: closeModal, className: 'recharge-key-close-icon', src: 'https://cdn.tuanzhzh.com/doubi-image/close-modal-icon.png' }),
                React.createElement("div", { className: 'exchange-code-content' },
                    React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u5151\u6362\u7801', className: 'input-style', value: value, onChange: valueChange }),
                    React.createElement(Button, { shape: 'rounded', className: 'sure-btn', onClick: exchargeCodeHandle },
                        React.createElement("span", null, "\u786E\u5B9A"))))));
};
export default ExchangeCode;
