var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
// import TopRolling from '@pages/Component/TopRolling/index'
import { useNavigate } from 'react-router-dom';
import { NavBar, List, Toast, InfiniteScroll } from 'antd-mobile';
import Apis from 'src/apis';
import moment from 'moment';
import CustomServiceModal from '../Component/CustomServiceModal';
import { Context, setCustomServiceModal } from '../../store';
import GiftBroadcast from '@pages/Component/GiftBroadcast';
import './index.less';
var borderColorMap = {
    '#FD3636': 'red-border',
    '#FFAD5E': 'yellow-border',
    '#D651FF': 'purple-border',
    '#2FBFFF': 'blue-border',
    '#FFFFFF': 'none-border'
};
var History = function () {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(true), hasMore = _b[0], setHasMore = _b[1];
    var dispatch = useContext(Context).dispatch;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    var navigate = useNavigate();
    var numRef = useRef(1);
    var isLoad = useRef(true);
    var back = useCallback(function () {
        navigate({
            pathname: '/home',
            search: window.location.search
        });
    }, []);
    var homeBack = function () {
        navigate({
            pathname: '/home',
            search: window.location.search
        });
    };
    var contactCustomService = function () {
        dispatch(setCustomServiceModal({
            visible: true
        }));
    };
    function loadMore() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getLotteryHistory()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    // 获取历史记录
    var getLotteryHistory = function () {
        if (!isLoad.current)
            return;
        isLoad.current = false;
        Apis.getLotteryHistory({
            userId: userInfo.userId,
            page: numRef.current,
            pageSize: 10
        }).then(function (res) {
            var _a, _b, _c;
            // let arr =[]
            // res.data?.forEach((item, i) => {
            //   const tempArr = item.giftList?.filter(list => list.giftId === item.maxValueGiftId) || []
            //   res.data[i].giftList = tempArr
            // })
            setHasMore(((_a = res.data) === null || _a === void 0 ? void 0 : _a.length) > 0);
            if ((_b = res.data) === null || _b === void 0 ? void 0 : _b.length) {
                numRef.current += 1;
            }
            (_c = res.data) === null || _c === void 0 ? void 0 : _c.forEach(function (item) {
                item.lotteryTime = moment(item.lotteryTime).format('YYYY-MM-DD HH:mm:ss');
                item.orderId = item.lotteryId.substr(0, 8);
            });
            setData(data.concat(res.data));
        }, function () {
            Toast.show({
                content: '获取历史记录出错'
            });
        }).finally(function () {
            isLoad.current = true;
        });
    };
    useEffect(function () {
        getLotteryHistory();
        numRef.current = 1;
    }, []);
    return React.createElement("div", { className: 'history-wrapper' },
        React.createElement(NavBar, { onBack: back }, "\u5386\u53F2\u8BB0\u5F55"),
        React.createElement(GiftBroadcast, null),
        React.createElement("div", { className: 'home-back', onClick: homeBack },
            React.createElement("img", { className: 'doubi-tiqu', src: 'https://cdn.tuanzhzh.com/doubi-image/home-icon.png' }),
            React.createElement("span", { className: 'doubi-tiqu-text' }, "\u8FD4\u56DE\u9996\u9875")),
        React.createElement("div", { className: 'home-back contact-customer', onClick: contactCustomService },
            React.createElement("img", { className: 'doubi-tiqu', src: 'https://cdn.tuanzhzh.com/doubi-image/kefu.png' }),
            React.createElement("span", { className: 'doubi-tiqu-text' }, "\u8054\u7CFB\u5BA2\u670D")),
        React.createElement("div", { className: 'extract-list-wrapper' },
            React.createElement(List, null, data === null || data === void 0 ? void 0 : data.map(function (item, i) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                return React.createElement(List.Item, { key: i },
                    React.createElement("div", { className: "extract-list-item ".concat(borderColorMap[(_a = item.gift) === null || _a === void 0 ? void 0 : _a.giftColor]) },
                        React.createElement("div", { className: 'extract-list-content' },
                            React.createElement("div", { className: 'list-item-top' },
                                React.createElement("span", null,
                                    React.createElement("span", { className: 'list-item-user' }, (_b = item.orderId) !== null && _b !== void 0 ? _b : '--')),
                                React.createElement("span", { className: 'list-item-prize-name', style: { color: (_c = item.gift) === null || _c === void 0 ? void 0 : _c.giftColor } }, ((_d = item.gift) === null || _d === void 0 ? void 0 : _d.giftName) || '--')),
                            React.createElement("div", { className: 'list-item-bottom' },
                                React.createElement("span", { className: 'list-item-time' }, (_e = item.lotteryTime) !== null && _e !== void 0 ? _e : '--'),
                                React.createElement("span", { className: 'list-item-bottom-right' },
                                    React.createElement("label", null, "\u6296\u97F3\u4EF7\u503C"),
                                    React.createElement("label", { className: 'list-item-douyin-number', style: { color: (_f = item.gift) === null || _f === void 0 ? void 0 : _f.giftColor } }, (_h = (_g = item.gift) === null || _g === void 0 ? void 0 : _g.dyMoneyAmount) !== null && _h !== void 0 ? _h : 0))))));
            })),
            React.createElement(InfiniteScroll, { loadMore: loadMore, hasMore: hasMore })),
        React.createElement(CustomServiceModal, null));
};
export default History;
