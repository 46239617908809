var _this = this;
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Mask, Button } from 'antd-mobile';
import { setLotteryModal, Context, lotteryDraw, setViewModal, setShowCurrentBroadCast } from '../../store';
import RechargeKey from '../RechargeKey';
import { genId } from 'src/utils';
import './index.less';
var Lottery = function () {
    var _a = useState({}), maxValue = _a[0], setMaxValue = _a[1];
    var _b = useState([]), list = _b[0], setList = _b[1];
    var _c = useContext(Context), state = _c.state, dispatch = _c.dispatch;
    var lotteryModal = state.lotteryModal, keyInfo = state.keyInfo;
    var userInfo = JSON.parse(window.localStorage.getItem('user') || '{}');
    // const userInfo = JSON.parse(window.localStorage.getItem('user') || '{}')
    var closeModal = function () {
        dispatch(setLotteryModal({
            visible: false
        }));
    };
    var showLotteryModal = function (num) {
        var currentKeyInfo = keyInfo.find(function (item) { return item.keyType === lotteryModal.currentBoxType; });
        if (currentKeyInfo.keyCount === 0) {
            dispatch(setViewModal({
                visible: true,
                num: currentKeyInfo.keyCount,
                type: lotteryModal.currentBoxType
            }));
            return;
        }
        dispatch(lotteryDraw({
            userId: userInfo.userId,
            keyInfo: {
                keyType: lotteryModal.currentBoxType,
                keyCount: num
            }
        }));
    };
    var renderDom = useMemo(function () {
        if (!maxValue.bigPicUrl) {
            var randomId = genId();
            return React.createElement("img", { className: 'load-draw-img', src: "https://cdn.tuanzhzh.com/doubi-image/open-dh.png?id=".concat(randomId) });
        }
        return '';
    }, [maxValue]);
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        var index = (_b = (_a = lotteryModal.lotteryDataSource) === null || _a === void 0 ? void 0 : _a.giftList) === null || _b === void 0 ? void 0 : _b.findIndex(function (item) { var _a; return item.giftId === ((_a = lotteryModal.lotteryDataSource) === null || _a === void 0 ? void 0 : _a.maxValueGiftId); });
        var findObj = ((_d = (_c = lotteryModal.lotteryDataSource) === null || _c === void 0 ? void 0 : _c.giftList) === null || _d === void 0 ? void 0 : _d.find(function (item) { var _a; return item.giftId === ((_a = lotteryModal.lotteryDataSource) === null || _a === void 0 ? void 0 : _a.maxValueGiftId); })) || {};
        var tempArr = JSON.parse(JSON.stringify(((_e = lotteryModal.lotteryDataSource) === null || _e === void 0 ? void 0 : _e.giftList) || []));
        tempArr.splice(index, 1);
        setMaxValue(findObj);
        // if (findObj?.giftColor === '#FFFFFF') {
        //   findObj.giftColor = '#2FBFFF'
        // }
        setMaxValue(findObj);
        setList(tempArr);
        if ((findObj === null || findObj === void 0 ? void 0 : findObj.lotteryLevel) === 1) {
            dispatch(setShowCurrentBroadCast({
                value: findObj.giftName,
                img: findObj.mainPicUrl
            }));
        }
    }, [lotteryModal]);
    return React.createElement("div", { className: 'lottery-wrapper' },
        React.createElement(Mask, { visible: lotteryModal.visible },
            React.createElement("div", { className: 'lottery-content' },
                React.createElement("img", { onClick: closeModal, className: 'lottery-close-icon', src: 'https://cdn.tuanzhzh.com/doubi-image/close-modal-icon.png' }),
                React.createElement("div", { className: 'load-draw-dh' }, renderDom),
                React.createElement("div", null,
                    maxValue.bigPicUrl && React.createElement("div", { className: 'max-value-lottery', style: {
                            boxShadow: "inset 0px 0px 22px 0px ".concat(maxValue.giftColor === '#FFFFFF' ? '#2FBFFF' : maxValue.giftColor),
                            border: "1px solid ".concat(maxValue.giftColor === '#FFFFFF' ? '#2FBFFF' : maxValue.giftColor)
                        } },
                        React.createElement("div", { className: 'max-value-lottery-content' },
                            React.createElement("img", { src: maxValue.mainPicUrl }),
                            React.createElement("div", { style: { color: maxValue.giftColor } }, maxValue.giftName),
                            React.createElement("div", { style: { color: maxValue.giftColor } },
                                "(\u4EF7\u503C\u6296\u5E01",
                                maxValue.dyMoneyAmount,
                                ")"))),
                    React.createElement("ul", { className: 'lottery-list-ul' }, list.map(function (item, i) {
                        return React.createElement("li", { className: 'lottery-list-item', key: i },
                            React.createElement("img", { src: item.mainPicUrl }),
                            React.createElement("span", { className: 'lottery-list-title', style: { color: item.giftColor } }, item.giftName),
                            React.createElement("span", { className: 'lottery-list-dymomeny-amount', style: { color: item.giftColor } },
                                React.createElement("label", null, "\u4EF7\u503C\u6296\u5E01"),
                                React.createElement("label", { className: 'lottery-list-amount' }, item.dyMoneyAmount)));
                    }))),
                React.createElement("div", { className: 'lottery-box-xiang-btn-wrapper' },
                    React.createElement(Button, { shape: 'rounded', className: 'one-key-btn', onClick: showLotteryModal.bind(_this, 1) },
                        React.createElement("span", null, "\u62BD1\u6B21")),
                    React.createElement(Button, { shape: 'rounded', className: 'ten-key-btn', onClick: showLotteryModal.bind(_this, 10) },
                        React.createElement("span", null, "10\u8FDE\u62BD"))))),
        React.createElement(RechargeKey, null));
};
export default Lottery;
